import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ImportExcel } from '@shared/_models';

import { EXCEL_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ExcelApiService {
  constructor(private httpClient: HttpClient) {}

  getImportTemplate(categoryId: string): Observable<Blob> {
    const url = `${EXCEL_API_URL}/download_import_template/${categoryId}`;
    return this.httpClient.post<Blob>(url, {}, { responseType: 'blob' as 'json' });
  }

  importExcelFile(formData: FormData, categoryId: string, dry_run: boolean): Observable<HttpEvent<ImportExcel>> {
    return this.httpClient.post<ImportExcel>(`${EXCEL_API_URL}/import_excel/${categoryId}?dry_run=${dry_run}`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getImportAnalysisSummary(formData: FormData, categoryId: string): Observable<Blob> {
    return this.httpClient.post<Blob>(`${EXCEL_API_URL}/download_import_report/${categoryId}`, formData, {
      responseType: 'blob' as 'json'
    });
  }
}

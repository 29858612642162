export const APP_SETTINGS_GLOBAL = {
  DATE_FORMAT_ISO: 'yyyy-MM-dd',
  DATE_FORMAT_SHORT_DATE: 'dd/MM/yy',
  DATE_FORMAT_SHORT_YEAR: 'yyyy',
  DATE_FORMAT_SHORT_MONTH: 'MM/yyyy',
  DATE_FORMAT_LONG_DATE: 'MMMM dd, y',
  DATE_FORMAT: 'dd/MM/yy, h:mm a',
  PAGE_SIZE: 20,
  PAGE_SIZE_OPTIONS: [10, 20, 50, 100],

  FILE_PREFIX_EXPERIMENT: 'experiment',
  FILE_PREFIX_RESEARCH_OBJECT: 'research-object',
  FILE_EXCEL_EXTENSION: 'xlsx',

  MODAL_DEFAULT_CONFIG: {
    size: 'lg',
    centered: true,
    backdrop: 'static' as boolean | 'static'
  }
};

<div class="lg-files-modal">
  <div class="lg-files-modal__icon-container">
    <span class="lg-files-modal__icon" [ngClass]="fileCategory | filesModalIconCSSClass: developmentType"></span>
  </div>
  <h2 class="lg-files-modal__header" data-testid="add-images-modal-header">Image manager for the {{ sectionName }}</h2>
  <div class="lg-files-modal__body images-list__body mb-1">
    <div class="row" *ngIf="sortedImages()?.length > 0; else noImages">
      <div class="col-lg-4" *ngFor="let image of sortedImages(); trackBy: trackById">
        <app-images-list-card [image]="image" [isMain]="image.id === mainImageId()"
          [developmentType]="developmentType" (deleteImage)="onDeleteImage(image.id)"
          (setAsMainImage)="onSetAsMainImage(image)" (showImageDetails)="onShowImageDetails(image)"
          (editName)="onEditImageName(image)">
        </app-images-list-card>
      </div>
    </div>
    <ng-template #noImages>
      <div class="images-list__placeholder-container">
        <span class="images-list__placeholder">No images uploaded so far.</span>
      </div>
    </ng-template>
  </div>
  <footer class="d-flex">
    <app-button [class]="developmentType + '-tertiary w-100 h-45 mr-8'" (click)="onAddMoreImagesClick()">+ Add more
    </app-button>
    <app-button [class]="developmentType + '-primary w-100 h-45 ml-8'" (click)="onCloseBtnClick()"
      data-test="closeButton">
      Close
    </app-button>
  </footer>
</div>

<ng-template #editNameModal let-modal>
  <app-generic-edit-modal
    [value]="selectedImage()?.name"
    [header]="editNameModalHeader()"
    [developmentType]="developmentType"
    [config]="{ label: 'Image name', name: 'image name' }"
    [validators]="editNameModalValidators"
    [backendErrorMessage]="changeImageNameError$ | async"
    [errorMessages]="{ isDuplicate: 'An image with this name already exists in this object.' }"
    placeholder="Enter image name"
    (save)="onSaveImageName($event, modal)"
    (closeModal)="modal.close()">
  </app-generic-edit-modal>
</ng-template>

import { FileDto, DevelopmentItemFile, DevelopmentItemUpdateFileDto } from '@shared/_models';

export function toDevelopmentItemUpdateFileDto(file: FileDto | DevelopmentItemFile): DevelopmentItemUpdateFileDto {
  if ('file' in file) {
    // DevelopmentItemFileDto
    return {
      file_id: file.file.id,
      name: file.name || file.file.name
    };
  }

  // FileDto
  return {
    file_id: file.id,
    name: file.name
  };
}

import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { ProjectDto } from '@shared/_models';

@Component({
  selector: 'app-files-upload-share-info',
  standalone: true,
  imports: [NgbPopover],
  templateUrl: './files-upload-share-info.component.html',
  styleUrl: './files-upload-share-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesUploadShareInfoComponent {
  project = input.required<ProjectDto>();
  shareFiles = output();
}

import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, input, Input, OnChanges, Output } from '@angular/core';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { FieldsViewMode, SectionType } from '@shared/_models';
import { DevelopmentItemService } from '@shared/_services/development-item.service';

@Component({
  selector: 'app-item-details-section',
  standalone: true,
  imports: [NgClass, NgIf, IconComponent],
  templateUrl: './item-details-section.component.html',
  styleUrls: ['./item-details-section.component.scss']
})
export class ItemDetailsSectionComponent implements OnChanges {
  @Input() sectionName: string;
  sectionType = input<SectionType>();
  @Input() showEditBtn = true;
  @Input() showAddMoreBtn = false;
  @Input() showArrangeBtn = false;
  @Input() editMode = false;
  @Input() mode: FieldsViewMode;
  @Input() addMode = false;
  @Input() disabled = false;
  @Input() activeArea = false;
  @Input() hiddenArea = false;

  @Output() edit = new EventEmitter<void>();
  @Output() addMore = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() arrangeClose = new EventEmitter<void>();

  readonly IconColors = IconColors;
  readonly FieldsViewMode = FieldsViewMode;
  readonly SectionType = SectionType;

  constructor(private readonly developmentItemService: DevelopmentItemService) {}

  ngOnChanges() {
    this.developmentItemService.setEditActiveStatus(!this.showArrangeBtn && this.editMode);
    if (this.addMode === true) {
      this.developmentItemService.setEditActiveStatus(this.addMode);
    }
  }
}

import { inject } from '@angular/core';

import { getShortGUID } from '@shared/_helpers';
import { FileUploadParams } from '@shared/_models/file-upload-params';
import { FileApiService } from '@shared/_services/file/file-api.service';
import { ImageApiService } from '@shared/_services/image/image-api.service';

import { FileCategory, FileUploadStatus } from '../../models';

export function toFileUploadStatusFactory() {
  const fileApiService = inject(FileApiService);
  const imageApiService = inject(ImageApiService);

  return (file: File, fileCategory: FileCategory, params: FileUploadParams): FileUploadStatus => {
    const formData = new FormData();
    formData.append(fileCategory === FileCategory.files ? 'file' : 'image', file);
    const uploadHttpEvents$ =
      fileCategory === FileCategory.files ? fileApiService.upload(formData, params) : imageApiService.upload(formData, params);

    return {
      guid: getShortGUID(),
      fileToUpload: file,
      uploadPending: false,
      uploadHttpEvents$,
      name: file.name
    };
  };
}

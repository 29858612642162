import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, computed, EventEmitter, Input, Output, Inject, input, output, viewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NgbModal, NgbPopoverModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs';

import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { trackByIndex, getFileSize } from '@shared/_helpers';
import { DevelopmentType, LinkedFileDto } from '@shared/_models';
import { FileSharedCountersComponent } from '@shared/_modules/file/file-shared-counters/file-shared-counters.component';

import { getExtension } from './utils';
import { GenericModalComponent } from '../../../_components/generic-modal/generic-modal.component';
import { CloseReason } from '../../../_components/generic-modal/utils';
import { IconComponent } from '../../../_components/icon/components/icon/icon.component';
import { ModalHeaderCenterComponent } from '../../../_components/modal-header-center/modal-header-center.component';
import { MoreActionsComponent } from '../../../_components/more-actions/more-actions.component';
import { MenuAction } from '../../../_components/more-actions/utils';
import { SharedFilesMiniListComponent } from '../../../_components/share-files-modal/components/shared-files-data-table/components/shared-files-mini-list/shared-files-mini-list.component';
import { AppSettings } from '../../../_configuration';
import { FileApiService } from '../../../_services/file';
import { FileShareEditNameModalComponent } from '../file-share-edit-name-modal/file-share-edit-name-modal.component';
import { TableItemBaseComponent } from '../table-item/table-item.component';

@Component({
  selector: 'app-file-table-item',
  standalone: true,
  imports: [
    CommonModule,
    NgbPopoverModule,
    NgbTooltip,
    IconComponent,
    FileSharedCountersComponent,
    MoreActionsComponent,
    SharedFilesMiniListComponent,
    FileSharedCountersComponent,
    FileShareEditNameModalComponent,
    GenericModalComponent,
    ModalHeaderCenterComponent
  ],
  templateUrl: './file-table-item.component.html',
  styleUrls: ['./file-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTableItemComponent extends TableItemBaseComponent<LinkedFileDto> {
  developmentType = input<DevelopmentType>();
  developmentItemName = input<string>();
  files = input<LinkedFileDto[]>();
  @Input() selected: boolean;
  @Output() readonly selectFile = new EventEmitter<void>();
  @Output() readonly unselectFile = new EventEmitter<void>();
  @Output() share = new EventEmitter<void>();
  downloadFile = output<string>();
  deleteShare = output<string>();
  editName = output<{ fileId: string; shareName: string }>();

  userName = computed<string>(() => (this.itemData() ? this.itemData().user_crt.name + ' ' + this.itemData().user_crt.surname : ''));
  shareNames = computed<string[]>(() => this.files().map(file => file.share_name));
  fileSize = computed<string>(() => getFileSize(this.itemData().size));
  isShared = computed<boolean>(() => this.itemData().count_experiments + this.itemData().count_research_objects > 1);
  linkedDevelopmentItems$ = toObservable(this.isShared).pipe(
    filter(isShared => isShared),
    switchMap(_ => this.fileApiService.getSharedDevelopmentItems(this.itemData().id))
  );

  fileShareEditNameModal = viewChild('fileShareEditNameModal');
  deleteShareConfirmationModal = viewChild('deleteShareConfirmationModal');

  moreActions: MenuAction<void>[] = [
    {
      callback: () => this.onEditNameClick(),
      label: 'Edit share name',
      icon: { fileName: 'pen_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.6rem', height: '0.6rem' }
    },
    null,
    {
      callback: () => this.share.emit(),
      label: 'Manage file and shares',
      icon: { fileName: 'share_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.7rem' }
    },
    {
      callback: () => this.onDownloadFile(),
      label: 'Download source file',
      icon: { fileName: 'export_icon_3.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.7rem' }
    },
    null,
    {
      callback: () => this.onDeleteShare(),
      label: 'Delete share',
      icon: { fileName: 'trash_3_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.6rem', height: '0.7rem' }
    }
  ];

  readonly IconColors = IconColors;
  readonly trackByIndex = trackByIndex;
  readonly getExtension = getExtension;

  constructor(
    @Inject(AppSettings) private readonly settings: AppSettings,
    private readonly ngbModalService: NgbModal,
    private readonly fileApiService: FileApiService,
    store: Store
  ) {
    super(store);
  }

  toggleFileSelection(event: Event): void {
    if ((event.target as HTMLInputElement).checked) {
      this.selectFile.emit();
    } else {
      this.unselectFile.emit();
    }
  }

  onEditNameClick() {
    this.ngbModalService.open(this.fileShareEditNameModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  onDeleteShare() {
    this.ngbModalService.open(this.deleteShareConfirmationModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  onDownloadFile() {
    this.downloadFile.emit(this.itemData().id);
  }

  onCloseDeleteShareConfirmation(reason: CloseReason) {
    this.ngbModalService.dismissAll();

    if (reason === CloseReason.CLOSE) {
      return;
    }

    // ACCEPT
    this.deleteShare.emit(this.itemData().id);
  }

  onEditName(fileId: string, shareName: string) {
    this.editName.emit({ fileId: fileId, shareName: shareName });
  }
}

<div class="loading-row">
  <div class="loading-col">
    <svg class="loading-icon" viewBox="0 0 77 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="loading-icon-gradient" x1="44.8453" y1="-0.0745342" x2="-15.775" y2="37.0267" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stop-color="#DE647C" />
          <stop offset="100%" stop-color="#F19F5D" />
        </linearGradient>
      </defs>
      <g class="loading-icon-layer loading-icon-layer--down">
        <path class="icon" d="M73.5724 54.8037C77.5865 57.0534 77.5865 62.1136 73.5724 64.3633L42.978 81.5093C40.4222 82.9416 36.7438 82.9416 34.188 81.5093L3.59362 64.3633C-0.420529 62.1136 -0.420529 57.0534 3.59362 54.8037L34.188 37.6577C36.7438 36.2254 40.4222 36.2254 42.978 37.6577L73.5724 54.8037Z"/>
        <path class="mask" d="M39.2873 44.3599C39.0269 44.214 38.1395 44.214 37.8791 44.3599L10.7144 59.5838L37.8791 74.8077C38.1395 74.9536 39.0269 74.9536 39.2873 74.8077L66.452 59.5838L39.2873 44.3599Z"/>
      </g>
      <g class="loading-icon-layer loading-icon-layer--center">
        <path class="icon" d="M73.5724 36.8037C77.5865 39.0534 77.5865 44.1136 73.5724 46.3633L42.978 63.5093C40.4222 64.9416 36.7438 64.9416 34.188 63.5093L3.59362 46.3633C-0.420529 44.1136 -0.420529 39.0534 3.59362 36.8037L34.188 19.6577C36.7438 18.2254 40.4222 18.2254 42.978 19.6577L73.5724 36.8037Z"/>
        <path class="mask" d="M39.2873 26.3599C39.0269 26.214 38.1395 26.214 37.8791 26.3599L10.7144 41.5838L37.8791 56.8077C38.1395 56.9536 39.0269 56.9536 39.2873 56.8077L66.452 41.5838L39.2873 26.3599Z"/>
      </g>
      <g class="loading-icon-layer loading-icon-layer--upper">
        <path class="icon" d="M73.5724 18.8037C77.5865 21.0534 77.5865 26.1136 73.5724 28.3633L42.978 45.5093C40.4222 46.9416 36.7438 46.9416 34.188 45.5093L3.59362 28.3633C-0.420529 26.1136 -0.420529 21.0534 3.59362 18.8037L34.188 1.65774C36.7438 0.225416 40.4222 0.225416 42.978 1.65774L73.5724 18.8037Z"/>
        <path class="mask" d="M39.2873 8.35993C39.0269 8.21401 38.1395 8.21401 37.8791 8.35993L10.7144 23.5838L37.8791 38.8077C38.1395 38.9536 39.0269 38.9536 39.2873 38.8077L66.452 23.5838L39.2873 8.35993Z"/>
      </g>
    </svg>

  </div>
  <div class="loading-col loading-col--left loading-col--content">
      Building<br/>Workspaces
    <div class="loading-dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>

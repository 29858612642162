import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

import { toParams } from '@app/shared/_helpers';
import {
  ResearchObjectAddDto,
  Pageable,
  PagedResponse,
  ResearchObjectDto,
  ResearchObjectUpdateDto,
  Searchable,
  ErrorMessageSection,
  UpdateArea,
  Sortable,
  NeighborsDto,
  DeleteMultipleResponseDto,
  ListIdsDto,
  DevelopmentItemUpdateFileDto,
  DevelopmentItemFileDto,
  DevelopmentItemUpdateImageDto,
  ImageDto
} from '@shared/_models';
import { EnvironmentService } from '@shared/_services/environment.service';

import { RESEARCH_OBJECTS_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectsApiService {
  constructor(
    private readonly http: HttpClient,
    private environmentService: EnvironmentService
  ) {}
  errorMessageSection$ = new BehaviorSubject<ErrorMessageSection>({
    errorResponse: false,
    section: null
  });

  getList(params: Searchable & Pageable & Sortable = {}) {
    return this.http
      .get<PagedResponse<ResearchObjectDto>>(`${RESEARCH_OBJECTS_API_URL}`, {
        params: toParams(params)
      })
      .pipe(shareReplay());
  }

  get(researchObjectId: string): Observable<ResearchObjectDto> {
    return this.http.get<ResearchObjectDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}`);
  }

  add(researchObject: ResearchObjectAddDto): Observable<ResearchObjectDto> {
    return this.http.post<ResearchObjectDto>(`${RESEARCH_OBJECTS_API_URL}`, researchObject);
  }

  update(researchObjectId: string, changes: Partial<ResearchObjectUpdateDto>) {
    return this.http.patch<ResearchObjectDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}`, changes);
  }

  delete(researchObjectId: string): Observable<string> {
    return this.http.delete<string>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}`);
  }

  deleteMultiple(ids: string[]): Observable<DeleteMultipleResponseDto> {
    return this.http.post<DeleteMultipleResponseDto>(`${RESEARCH_OBJECTS_API_URL}/batch_delete`, ids);
  }

  getAllResearchObjectsIds(params: Params = {}): Observable<ListIdsDto> {
    return this.http.get<ListIdsDto>(`${RESEARCH_OBJECTS_API_URL}/list_ids`, {
      params
    });
  }

  setErrorMessage(errorResponse: HttpErrorResponse, section: UpdateArea): void {
    this.errorMessageSection$.next({ errorResponse, section });
  }

  getNeighborResearchObjectData(researchObjectId: string, params: Params) {
    return this.http.get<NeighborsDto>(`${this.environmentService.environment.apiUrl}/research_objects/${researchObjectId}/neighbors`, {
      params
    });
  }

  addShare(researchObjectId: string, share: DevelopmentItemUpdateFileDto) {
    return this.http.post<DevelopmentItemFileDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}/add_share`, share);
  }

  updateShare(researchObjectId: string, share: DevelopmentItemUpdateFileDto) {
    return this.http.patch<DevelopmentItemFileDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}/update_share/${share.file_id}`, {
      name: share.name
    });
  }

  removeShares(researchObjectId: string, shareIds: string[]) {
    return this.http.post<void>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}/remove_shares`, shareIds);
  }

  addImage(researchObjectId: string, payload: DevelopmentItemUpdateImageDto, isMain?: boolean) {
    const params: Params = isMain ? { main_image: 'true' } : {};

    return this.http.post<ImageDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}/add_image`, payload, { params });
  }

  removeImages(researchObjectId: string, imageIds: string[]) {
    return this.http.post<void>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}/remove_images`, imageIds);
  }
}

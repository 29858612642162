import { ChangeDetectionStrategy, Component, input, output, signal, ChangeDetectorRef } from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';

import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { ModalHeaderLeftComponent } from '@shared/_components/modal-header-left/modal-header-left.component';
import { DevelopmentType } from '@shared/_models';

@Component({
  selector: 'app-smiles-wysiwyg-modal',
  standalone: true,
  imports: [GenericModalComponent, ModalHeaderLeftComponent],
  templateUrl: './smiles-wysiwyg-modal.component.html',
  styleUrl: './smiles-wysiwyg-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmilesWysiwygModalComponent {
  value = input<string>('');
  developmentType = input.required<DevelopmentType>();
  categoryName = input.required<string>();
  fieldName = input.required<string>();
  close = output<void>();
  update = output<string>();

  error = signal<string>(null);
  ketcher: any;

  readonly CloseReason = CloseReason;
  readonly DevelopmentType = DevelopmentType;

  constructor(private cdr: ChangeDetectorRef) {}

  async onClose(reason: CloseReason) {
    if ([CloseReason.CLOSE, CloseReason.DISMISS].includes(reason)) {
      this.close.emit();
      return;
    }

    this.error.set(null);

    try {
      const smiles = await this.ketcher.getSmiles();

      this.update.emit(smiles);
    } catch (error) {
      console.warn(error);
      this.error.set('Conversion error. Could not be generated string for given molecule.');
    }
  }

  async onKetcherLoaded() {
    const getKatcher = () => {
      const ketcherFrame = document.getElementById('ifKetcher') as any;

      if ('contentDocument' in ketcherFrame) return ketcherFrame.contentWindow.ketcher;

      // IE7
      return (<any>document).frames['ifKetcher'].window.ketcher;
    };

    await firstValueFrom(timer(1000));
    this.ketcher = getKatcher();
    this.ketcher.setMolecule(this.value());
    this.#setupErrorVisibility();
  }

  #setupErrorVisibility() {
    this.ketcher.editor.subscribe('change', () => {
      if (!this.error()) return;

      this.error.set(null);
      this.cdr.detectChanges();
    });
  }
}

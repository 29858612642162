<div class="share-icon-container" [class.share-icon-container--readonly]="readonly()">
  @if (linkedFile().count_research_objects > 0) {
    <div class="shared__objects">
      <img src="../../../../../assets/icons/atom_gradient_icon.svg" />
      <span class="shared__object_count">{{ linkedFile()?.count_research_objects }}</span>
    </div>
  }
  @if (linkedFile().count_experiments > 0) {
    <div class="shared__objects">
      <img src="../../../../../assets/icons/chart_gradient_icon.svg" />
      <span class="shared__object_count">{{ linkedFile()?.count_experiments }}</span>
    </div>
  }
</div>

import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { isUnique } from '@shared/_helpers';

export function duplicateStringValidator(reservedValues: () => string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || typeof control.value !== 'string') {
      return null;
    }

    const isDuplicate = !isUnique(reservedValues(), control.value.trim());

    return isDuplicate ? { isDuplicate: true } : null;
  };
}

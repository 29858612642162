<app-generic-modal
  [closeButton]="{ text: 'Cancel' }"
  [acceptButton]="{ text: 'Save', disabled: control.invalid }"
  [developmentType]="developmentType()"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
    <app-input-with-validation
      [control]="control"
      [config]="config()"
      [backendErrorMessage]="backendErrorMessage()"
      [errorMessages]="errorMessages()"
    >
      <app-input-with-clear
        [formControl]="control"
        [hasError]="backendErrorMessage() || (control.touched && control.invalid)"
        [placeholder]="placeholder()"
      ></app-input-with-clear>
    </app-input-with-validation>
</app-generic-modal>

<ng-template #modalHeaderTemplate let-modal>
    <app-modal-header-left
      [header]="header().top"
      [imgSrc]="header().icon"
      [whiteIconFilter]="header().whiteIconFilter"
      [itemName]="header().middle"
      [developmentType]="developmentType()"
      [categoryName]="header().bottom"
      (closeModal)="closeModal.emit()">
    </app-modal-header-left>
</ng-template>

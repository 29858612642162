import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { DetailsFileThumbComponent } from '@app/shared/_components/details-file-thumb/details-file-thumb.component';
import { ItemDetailsSectionComponent } from '@shared/_components/item/item-details/item-details-section/item-details-section.component';
import { OverflowContentPluginToNgbTooltipDirective } from '@shared/_directives';
import { DevelopmentType, DevelopmentItemFileDto } from '@shared/_models';
import { DevelopmentItemService } from '@shared/_services/development-item.service';

@Component({
  selector: 'app-item-details-recent-files',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    RouterLink,
    NgbModule,
    ItemDetailsSectionComponent,
    DetailsFileThumbComponent,
    ButtonComponent,
    OverflowContentPluginToNgbTooltipDirective
  ],
  templateUrl: './item-details-recent-files.component.html',
  styleUrls: ['./item-details-recent-files.component.scss']
})
export class ItemDetailsRecentFilesComponent {
  @Input() files: DevelopmentItemFileDto[] = [];
  @Input() developmentType: DevelopmentType;
  @Input() editable: boolean;
  @Output() readonly showAllFiles = new EventEmitter<null>();
  @Output() readonly addFiles = new EventEmitter<null>();
  @Output() readonly download = new EventEmitter<string>();

  readonly DevelopmentType = DevelopmentType;

  constructor(private developmentItemService: DevelopmentItemService) {}

  onAddMore(): void {
    this.addFiles.emit();
  }

  onFileThumbClick(fileId: string) {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }

    this.download.emit(fileId);
  }
}

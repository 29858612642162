<div class="mini-list">
  <div class="mini-list__header">
    <img class="mini-list__icon" src="../../../../../../../../assets/icons/file_icon.svg" />
    <div class="mini-list__title-container">
      <div class="mini-list__title">Source file</div>
      <div class="mini-list__file-name">{{ fileName() }}</div>
    </div>
    <button class="mini-list__close-btn">
      <img src="../../../../../../../../assets/icons/close_icon.svg" alt="close icon" />
    </button>
  </div>
  <div class="mini-list__body">
    <div class="mini-list__info">
      <span class="mini-list__info-title">File is shared with</span>
      <img src="../../../../../../../../assets/icons/atom_gradient_icon.svg" alt="ro icon" class="mini-list__ro-icon mx-1" />
      {{ roCount() }}
      <img src="../../../../../../../../assets/icons/exp_placeholder_hover_icon.svg" alt="exp icon" class="mini-list__exp-icon mx-1" />
      {{ expCount() }}
    </div>
    <div class="mini-list__list">
      @for (item of linkedItems(); track item) {
        <div class="mini-list__record">
          <div
            class="mini-list__tile"
            [class.mini-list__tile--ro]="item.development_type === DevelopmentType.researchObject"
            [class.mini-list__tile--exp]="item.development_type === DevelopmentType.experiment"
          >
            {{ item.oid }}
          </div>
          <div class="mini-list__item-name">
            {{ item.name }}
          </div>
        </div>
      }
    </div>
  </div>
</div>

<div class="datatable__additional-row">
  <div (click)="switchShowRelatedItems()" class="additional-row-details" [ngClass]="{ 'color-blue': showRelatedItems === true }">
    <div [ngClass]="{ 'color-blue': showRelatedItems === true }">
      <span class="additional-row-message" data-testid="object-details">{{ detailsMessage }}</span>
      <span>Details</span>
    </div>
    <img *ngIf="!showRelatedItems" class="arrow_list" [src]="'assets/icons/icon_arrow_list_default.svg'" />
    <img *ngIf="showRelatedItems" class="arrow_list" [src]="'assets/icons/icon_arrow_list_active.svg'" />
  </div>
</div>

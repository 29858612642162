import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, output, input, computed } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { MoreActionsComponent } from '@shared/_components/more-actions/more-actions.component';
import { MenuAction, MENU_ITEM_DIVIDER } from '@shared/_components/more-actions/utils';
import { DevelopmentType, Image } from '@shared/_models';
import { ImageThumbComponent } from '@shared/_modules/file/image-thumb/image-thumb.component';
import { FormatFileSizePipe } from '@shared/_modules/file/pipes/format-file-size.pipe';
import { MimeSubtypePipe } from '@shared/_modules/file/pipes/mime-subtype.pipe';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { FileSafeUrlPipe } from '@shared/pipes/file-safe-url.pipe';

@Component({
  selector: 'app-images-list-card',
  standalone: true,
  imports: [
    CommonModule,
    NgbPopoverModule,
    DevelopmentTypeCssClassPipe,
    FileSafeUrlPipe,
    FormatFileSizePipe,
    MimeSubtypePipe,
    ImageThumbComponent,
    MoreActionsComponent
  ],
  templateUrl: './images-list-card.component.html',
  styleUrls: ['./images-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesListCardComponent {
  @Input() image: Image;
  @Input() developmentType: DevelopmentType;
  isMain = input<boolean>();
  @Output() readonly deleteImage = new EventEmitter<null>();
  @Output() readonly setAsMainImage = new EventEmitter<null>();
  @Output() readonly showImageDetails = new EventEmitter<null>();
  editName = output<void>();

  readonly DevelopmentType = DevelopmentType;
  readonly actions = computed<MenuAction<void>[]>(() => [
    {
      callback: () => this.onShowMoreDetailsBtnClick(),
      label: 'Show more details',
      className: 'show-more-details'
    },
    {
      callback: () => this.onSetAsMainImageClick(),
      label: 'Set as main image',
      disabled: this.isMain(),
      className: 'set-as-main-image'
    },
    {
      callback: () => this.editName.emit(),
      label: 'Edit name'
    },
    MENU_ITEM_DIVIDER,
    {
      callback: () => this.onDeleteImageClick(),
      label: 'Delete image',
      className: 'delete-image'
    }
  ]);

  onDeleteImageClick(): void {
    this.deleteImage.emit();
  }

  onSetAsMainImageClick(): void {
    this.setAsMainImage.emit();
  }

  onShowMoreDetailsBtnClick(): void {
    this.showImageDetails.emit();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import _ from 'lodash';

import { TEMPLATE_ROUTE_NAME } from '@app/main/utils';
import { CATEGORIES_EDIT_QUERY_PARAMETER, CategoriesEditQueryParamValues, ListViewQueryParams } from '@shared/_models/query-params';

import { DevelopmentType, ViewMode } from '../_models';
import { ActivatedRouteParamsService } from './activated-route-params.service';
import { ExperimentParamsService } from './experiment/experiment-params.service';
import { ResearchObjectParamsService } from './research-object/research-object-params.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    private readonly router: Router,
    private readonly activatedRouteParamsService: ActivatedRouteParamsService,
    private readonly researchObjectParamsService: ResearchObjectParamsService,
    private readonly experimentParamsService: ExperimentParamsService
  ) {}

  get params(): Params {
    return this.activatedRouteParamsService.getParams();
  }

  goToExperimentInfo(workspaceId, projectId: string, experimentId: string, categoryId: string, preserveQueryParams = false): void {
    if (!experimentId || !categoryId) {
      return;
    }

    const viewMode = this.experimentParamsService.getCurrentParams().viewMode;
    const outlets = {
      primary: [viewMode, categoryId, experimentId, 'info']
    };
    const route = ['', 'app', workspaceId, projectId, 'experiments', { outlets }];

    this.router.navigate(route, { queryParamsHandling: preserveQueryParams ? 'preserve' : '' });
  }

  goToExperimentFiles(
    workspaceId,
    projectId: string,
    experimentId: string,
    categoryId: string,
    newTab: boolean,
    preserveQueryParams = false
  ): void {
    if (!experimentId || !categoryId) {
      return;
    }

    const viewMode = this.experimentParamsService.getCurrentParams().viewMode;
    const outlets = {
      primary: [viewMode, categoryId, experimentId, 'files']
    };
    const route = ['', 'app', workspaceId, projectId, 'experiments', { outlets }];

    if (newTab) {
      const url = this.router.serializeUrl(this.router.createUrlTree(route));
      window.open(url, '_blank');
    } else {
      this.router.navigate(route, { queryParamsHandling: preserveQueryParams ? 'preserve' : '' });
    }
  }

  goToExperimentsListWithParams(
    workspaceId: string,
    projectId: string,
    categoryId: string = null,
    queryParams?: ListViewQueryParams
  ): Promise<boolean> {
    categoryId = categoryId ? categoryId : this.params.categoryId;
    const viewMode = this.experimentParamsService.getCurrentParams().viewMode;
    const outlets = {
      primary: [viewMode, categoryId]
    };
    const route = ['', 'app', workspaceId, projectId, 'experiments', { outlets }];
    return this.router.navigate(route, { queryParams: { page: 1, limit: 20, ...queryParams } });
  }

  goToResearchObjectInfo(
    workspaceId: string,
    projectId: string,
    researchObjectId: string,
    categoryId: string,
    preserveQueryParams = false
  ): void {
    if (!researchObjectId || !categoryId) {
      return;
    }
    const viewMode = this.researchObjectParamsService.getCurrentParams().viewMode;
    const outlets = {
      ...{ primary: [viewMode, categoryId, researchObjectId, 'info'] }
    };
    const route = ['', 'app', workspaceId, projectId, 'research-objects', { outlets }];
    this.router.navigate(route, { queryParamsHandling: preserveQueryParams ? 'preserve' : '' });
  }

  goToResearchObjectFiles(
    workspaceId: string,
    projectId: string,
    researchObjectId: string,
    categoryId: string,
    newTab: boolean,
    preserveQueryParams = false
  ): void {
    if (!researchObjectId || !categoryId) {
      return;
    }
    const viewMode = this.researchObjectParamsService.getCurrentParams().viewMode;
    const outlets = {
      ...{ primary: [viewMode, categoryId, researchObjectId, 'files'] }
    };
    const route = ['', 'app', workspaceId, projectId, 'research-objects', { outlets }];

    if (newTab) {
      const url = this.router.serializeUrl(this.router.createUrlTree(route));
      window.open(url, '_blank');
    } else {
      this.router.navigate(route, { queryParamsHandling: preserveQueryParams ? 'preserve' : '' });
    }
  }

  goToResearchObjectsListWithParams(
    workspaceId: string,
    projectId: string,
    categoryId: string = null,
    queryParams?: ListViewQueryParams,
    viewMode?: ViewMode
  ): Promise<boolean> {
    categoryId = categoryId ? categoryId : this.params.categoryId;

    const outlets = {
      primary: [viewMode || this.researchObjectParamsService.getCurrentParams().viewMode, categoryId]
    };
    const route = ['', 'app', workspaceId, projectId, 'research-objects', { outlets }];

    return this.router.navigate(route, { queryParams: { page: 1, limit: 20, ...queryParams } });
  }

  goToProjectsList(workspaceId: string) {
    const route = ['', 'app', workspaceId, 'projects', ViewMode.grid];
    this.router.navigate(route, { queryParams: {} });
  }

  toggleTemplatePreview(activatedRoute: ActivatedRoute, showTemplatePreview: boolean) {
    this.router.navigate([], {
      relativeTo: activatedRoute,
      queryParams: {
        [CATEGORIES_EDIT_QUERY_PARAMETER]: showTemplatePreview
          ? CategoriesEditQueryParamValues.preview
          : CategoriesEditQueryParamValues.noPreview
      },
      queryParamsHandling: 'merge'
    });
  }

  goToTemplateEdit(workspaceId: string, projectId: string, categoryId: string, developmentType: DevelopmentType) {
    const outlets = {
      primary: [developmentType, categoryId, 'edit']
    };
    const route = ['', 'app', workspaceId, projectId, TEMPLATE_ROUTE_NAME, { outlets }];
    this.router.navigate(route);
  }

  goToCategoriesEdit(
    workspaceId: string,
    projectId: string,
    categoryId: string = null,
    developmentType: DevelopmentType,
    withPreview = false,
    queryParams?: ListViewQueryParams
  ) {
    const combinedQueryParams: ListViewQueryParams = {
      ...queryParams,
      [CATEGORIES_EDIT_QUERY_PARAMETER]: withPreview ? CategoriesEditQueryParamValues.preview : CategoriesEditQueryParamValues.noPreview
    };
    if (developmentType === DevelopmentType.researchObject) {
      this.goToResearchObjectsListWithParams(workspaceId, projectId, categoryId, combinedQueryParams);
    }
    if (developmentType === DevelopmentType.experiment) {
      this.goToExperimentsListWithParams(workspaceId, projectId, categoryId, combinedQueryParams);
    }
  }

  leaveCategoriesEdit(activatedRoute: ActivatedRoute) {
    const currentQueryParams = activatedRoute.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: activatedRoute,
      queryParams: _.omit(currentQueryParams, [CATEGORIES_EDIT_QUERY_PARAMETER])
    });
  }
}

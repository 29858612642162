<div
  class="card-copy"
  (click)="onCopyIcon(copyTooltip)"
  ngbTooltip="Copied!"
  #copyTooltip="ngbTooltip"
  triggers="click">
  <img
    src="../../../../../../assets/icons/copy_icon.svg"
    class="card-icon"
  />
</div>

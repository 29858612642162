import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, input, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { NgbTypeahead, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, takeUntil } from 'rxjs';

export class SearchSelectModel {
  item: any;
}

// TODO: refactor to use ControlValueAccessor, remove: controlName, form
@Component({
  selector: 'app-search-select',
  standalone: true,
  imports: [NgIf, FormsModule, NgbModule],
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() searchFunction: OperatorFunction<string, readonly string[]>;
  @Input() resultTemplate: any;
  @Input() inputFormatter: any;
  @Input() invalid: boolean;
  @Input() form: FormGroup;
  @Input() placeholder = 'start typing to search';
  hoverColored = input<boolean>();
  @Output() selectItem = new EventEmitter<any>();
  @Output() focused = new EventEmitter();
  @Output() clearSelection = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngModel: any;
  hasFocus = false;
  readonly destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.form.controls[this.controlName].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.ngModel = data;
      this.cdRef.detectChanges();
    });
  }

  onKeyup(text: string) {
    if (text == '') {
      this.setValue(text);
      this.selectItem.emit(null);
    }
  }

  onSelectItem(selectedItem: SearchSelectModel, select: HTMLInputElement) {
    select.blur();
    this.setValue(selectedItem.item);
    this.selectItem.emit(selectedItem.item);
  }

  onFocusin() {
    this.hasFocus = true;
    this.focused.emit();
  }

  onFocusout(typehead: NgbTypeahead) {
    if (this.ngModel != this.form.controls[this.controlName].value) {
      this.ngModel = this.form.controls[this.controlName].value;
    }
    this.hasFocus = false;
    typehead.dismissPopup();
  }

  onClear(select: HTMLInputElement) {
    select.focus();
    this.setValue(null);
    this.selectItem.emit();
    this.clearSelection.emit();
  }

  private setValue(value: any) {
    this.form.controls[this.controlName].setValue(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

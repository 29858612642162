<div class="modal-header-left">
  <div class="modal-header-left__icon-container modal-background--{{ developmentType }}">
    <i [ngClass]="{ 'icon-filter-white': whiteIconFilter }" class="modal-header-left__icon icon-filter--{{ developmentType }}">
      <img [src]="fullImagePath" />
    </i>
  </div>
  <div data-testid="modal-left-header" class="modal-header-left__header-content">
    <span class="modal-header-left__section">
      {{ header }}
    </span>
    <span class="modal-header-left__item-name">{{ itemName }}</span>
    <span class="modal-header-left__category-name">{{ categoryName }}</span>
  </div>

  <button
    *ngIf="isTooltipBtnVisible"
    class="modal-header-left__header-info-btn"
    [ngbPopover]="tooltipContent"
    [popoverTitle]="tooltipHeader"
    popoverClass="lg-popover"
    triggers="click"></button>
  @if (!hideCloseIcon()) {
    <button data-testid="close-modal-btn" class="modal-header-left__header-btn" (click)="closeModal.emit()"></button>
  }
</div>

<app-generic-modal
  [closeButton]="{ text: 'Cancel' }"
  [acceptButton]="{ text: 'Save', disabled: form.controls.name.errors?.isDuplicate }"
  [developmentType]="developmentType()"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <form [formGroup]="form">
    <app-input-with-validation
      [control]="form.controls.name"
      [config]="{label:  'Share name*' , name: 'share name'}"
    >
      <app-input-with-clear
        [formControl]="form.controls.name"
        [hasError]="form.controls.name.touched && form.controls.name.invalid"
        placeholder="Name for share"
      ></app-input-with-clear>
    </app-input-with-validation>

    @if(sourceFile()) {
    <div class="description-container">
      <app-editor
          [formControl]="form.controls.description"
          label="Description"
          maxLength="2000"
          ngDefaultControl
      ></app-editor>
    </div>
    }
  </form>
</app-generic-modal>

<ng-template #modalHeaderTemplate let-modal>
  <app-modal-header-left
    [header]="sourceFile() ? 'Editing name or description' : 'Editing share in'"
    imgSrc="share_icon_big.svg"
    [itemName]="sourceFile() ? form.get('name').value : developmentItemName()"
    [categoryName]="sourceFile() ? 'Source File' : (category$ | async)?.name"
    [developmentType]="developmentType()"
    (closeModal)="closeModal.emit()"
  ></app-modal-header-left>
</ng-template>

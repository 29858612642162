import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { DevelopmentItemLinkedFilesShortDto, DevelopmentType } from '@shared/_models';

@Component({
  selector: 'app-shared-files-mini-list',
  standalone: true,
  imports: [IconComponent, JsonPipe],
  templateUrl: './shared-files-mini-list.component.html',
  styleUrl: './shared-files-mini-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedFilesMiniListComponent {
  fileName = input<string>();
  linkedItems = input<DevelopmentItemLinkedFilesShortDto[]>([]);
  roCount = input<number>(0);
  expCount = input<number>(0);
  IconColors = IconColors;
  DevelopmentType = DevelopmentType;
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal,
  viewChild,
  Inject,
  output,
  input
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { NgbPopoverModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { merge, BehaviorSubject } from 'rxjs';

import { ButtonComponent } from '@shared/_components/button/button.component';
import { GenericEditModalComponent } from '@shared/_components/generic-edit-modal/generic-edit-modal.component';
import { EditModalHeaderArgs } from '@shared/_components/generic-edit-modal/utils';
import { AppSettings } from '@shared/_configuration';
import { trackById } from '@shared/_helpers';
import { ImageDto, ImageMetaUpdateData, DevelopmentType, Image } from '@shared/_models';
import { FileService } from '@shared/_modules/file/file.service';
import { ImageThumbComponent } from '@shared/_modules/file/image-thumb/image-thumb.component';
import { ImagesListCardComponent } from '@shared/_modules/file/images-list-card/images-list-card.component';
import { FilesModalIconCSSClassPipe } from '@shared/_modules/file/pipes/files-modal-icon-css-class.pipe';
import { FormatFileSizePipe } from '@shared/_modules/file/pipes/format-file-size.pipe';
import { MimeSubtypePipe } from '@shared/_modules/file/pipes/mime-subtype.pipe';
import { duplicateStringValidator } from '@shared/_validators/duplicate-string.validator';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { FileSafeUrlPipe } from '@shared/pipes/file-safe-url.pipe';
import { StartCasePipe } from '@shared/pipes/start-case.pipe';

import { FileCategory } from '../models/file-category';

@Component({
  selector: 'app-images-list',
  standalone: true,
  imports: [
    CommonModule,
    NgbPopoverModule,
    FilesModalIconCSSClassPipe,
    ImagesListCardComponent,
    StartCasePipe,
    FileSafeUrlPipe,
    DevelopmentTypeCssClassPipe,
    FormatFileSizePipe,
    MimeSubtypePipe,
    ImageThumbComponent,
    ButtonComponent,
    GenericEditModalComponent
  ],
  templateUrl: './images-list.component.html',
  styleUrls: ['./images-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesListComponent {
  @Input() fileCategory: FileCategory;
  @Input() developmentType: DevelopmentType;
  @Input() sectionName: string;
  images = input.required<Image[]>();
  mainImageId = input.required<string>();
  changeImageNameError = input<string>();
  changeImageNameErrorReset$ = new BehaviorSubject<string>(null);
  changeImageNameError$ = merge(toObservable(this.changeImageNameError), this.changeImageNameErrorReset$.asObservable());

  @Output() closeImage = new EventEmitter<null>();
  @Output() addMoreImages = new EventEmitter<null>();
  @Output() setAsMainImage = new EventEmitter<string>();
  @Output() deleteImage = new EventEmitter<string>();
  changeImageName = output<ImageMetaUpdateData & { modal: NgbModalRef }>();
  editNameModal = viewChild('editNameModal');

  sortedImages = computed(() => this.images().sort((a, _) => (a.id === this.mainImageId() ? -1 : 0)));
  selectedImage = signal<Image>(null);
  reservedNames = computed<string[]>(() =>
    this.images()
      .filter(image => image.id !== this.selectedImage()?.id)
      .map(image => image.name)
  );
  editNameModalHeader = computed<EditModalHeaderArgs>(() => ({
    top: 'Editin name for',
    middle: 'Image',
    bottom: this.selectedImage()?.name,
    icon: 'images_placeholder_dark_icon.svg',
    whiteIconFilter: true
  }));
  editNameModalValidators = [Validators.required, duplicateStringValidator(this.reservedNames)];

  readonly trackById = trackById;
  readonly DevelopmentType = DevelopmentType;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly fileService: FileService,
    private readonly ngbModal: NgbModal
  ) {}

  onCloseBtnClick(): void {
    this.closeImage.emit();
  }

  onDeleteImage(imageId: string): void {
    this.deleteImage.emit(imageId);
  }

  onSetAsMainImage(image: ImageDto): void {
    this.setAsMainImage.emit(image.id);
  }

  onAddMoreImagesClick(): void {
    this.addMoreImages.emit();
  }

  onShowImageDetails(image: ImageDto): void {
    this.fileService.emitImageForDetailsModal(image);
  }

  onEditImageName(image: Image): void {
    this.selectedImage.set(image);
    this.changeImageNameErrorReset$.next(null);
    this.ngbModal.open(this.editNameModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  onSaveImageName(name: string, modal: NgbModalRef): void {
    if (name === this.selectedImage().name) {
      modal.close();

      return;
    }

    this.changeImageName.emit({ imageId: this.selectedImage().id, payload: { name }, modal });
  }
}

<div class="input-group mb-3">
  <app-search-select
    [controlName]="FORM_KEYS.MEMBERS_DROPDOWN_CONTROL"
    [form]="membersDropdown"
    [searchFunction]="searchMembers"
    [resultTemplate]="membersResultTemplate"
    (selectItem)="onAddMember($event)"
    (focused)="checkForMembersDuplicates()"
    placeholder="Search for member to add ...">
  </app-search-select>
</div>
<ng-template #membersResultTemplate let-member="result" let-searchTerm="term">
  <ng-template #noMembersResult>No Members found with a name containing "{{ searchTerm }}".</ng-template>
  <div *ngIf="member !== null; else noMembersResult" class="members-dropdown-item">
    <img class="members-dropdown-item__icon" src="assets/icons/user_light_icon.svg" />
    <div class="members-dropdown-item__info">
      <span class="members-dropdown-item__info__name">{{ member.user.name }} {{ member.user.surname }}</span>
      <span class="members-dropdown-item__info__role">{{ member.role }}</span>
    </div>
  </div>
</ng-template>

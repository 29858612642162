import { IconColors } from '@shared/_components/icon/utils/icon-colors';

export interface Icon {
  fileName: string;
  color: IconColors;
  width: string;
  height: string;
  hoverColor?: IconColors;
}

export interface MenuAction<T> {
  callback: (entity: T) => void;
  label: string;
  disabled?: boolean;
  highlightRed?: boolean;
  icon?: Icon;
  className?: string;
}

export const MENU_ITEM_DIVIDER = null;

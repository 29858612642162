<div class="grid-container" [ngClass]="{ 'grid-container--selected': selected }">
  <div class="selection-col text-center">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" (change)="toggleFileSelection($event)" [checked]="selected" />
    </div>
  </div>
  <div data-testid="file-name" class="item-label name-col">
    <div class="share-name-wrapper" (click)="onDownloadFile()">
      <app-icon class="file-icon" width="1.1rem" height="1.1rem" fileName="share_icon.svg" [color]="IconColors.mediumGrey"></app-icon>
      {{ itemData().share_name }}
    </div>
  </div>
  @if (itemData().count_experiments + itemData().count_research_objects > 1) {
    <div class="item-label share-col" [ngbPopover]="popoverContent" triggers="click" placement="bottom">
      <app-file-shared-counters [linkedFile]="itemData()"></app-file-shared-counters>
    </div>
  } @else {
    <div class="item-label share-col"></div>
  }
  <ng-template #popoverContent>
    <app-shared-files-mini-list
      [fileName]="itemData().name"
      [roCount]="itemData().count_research_objects"
      [expCount]="itemData().count_experiments"
      [linkedItems]="(linkedDevelopmentItems$ | async) || []"
    ></app-shared-files-mini-list>
  </ng-template>
  @if (itemData().description) {
    <div class="item-label description-col align-center" [ngbPopover]="popoverDescription" popoverTitle="File description" triggers="click" placement="bottom">
      <app-icon
        class="note-icon"
        width="1rem"
        height="1rem"
        fileName="note_icon.svg"
        [color]="IconColors.mediumGrey"
        [hoverColor]="IconColors.blue"
      ></app-icon>
      <ng-template #popoverDescription>
        <div [innerHTML]="itemData().description" class="description-popover"></div>
      </ng-template>
    </div>
  } @else {
    <div class="item-label description-col"></div>
  }
  <div class="item-label align-center">
    <div [ngbTooltip]="userName()">
      <img src="assets/icons/user_2_icon.svg" alt="user icon" />
    </div>
  </div>
  <div class="item-label align-center" data-testid="file-type">{{ getExtension(itemData().name) | uppercase }}</div>
  <div class="item-label align-right">{{ itemData()?.date_created | date: 'dd.MM.YYYY' }}</div>
  <div class="item-label align-right item-more-actions-container">
    <div class="item-label item-more-actions">
      <app-more-actions [actions]="moreActions" [color]="IconColors.mediumGrey" [hoverColor]="IconColors.blue" ></app-more-actions>
    </div>
  </div>
</div>
<ng-template #fileShareEditNameModal let-modal>
  <app-file-share-edit-name-modal
    [fileName]="itemData().share_name"
    [developmentType]="developmentType()"
    [developmentItemName]="developmentItemName()"
    [checkForInitialError]="false"
    [shareNames]="shareNames()"
    (closeModal)="modal.close()"
    (saveBtnClick)="onEditName($event.fileId, $event.name)"
  ></app-file-share-edit-name-modal>
</ng-template>
<ng-template #deleteShareConfirmationModal let-modal>
  <app-generic-modal
    [closeButton]="{ text: 'No, keep it' }"
    [acceptButton]="{ text: 'Yes, delete it' }"
    [developmentType]="developmentType()"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="onCloseDeleteShareConfirmation($event)"
  >
    <ng-template #headerTemplate>
      <app-modal-header-center
        [imgSrc]="'toast_icon_warning.png'"
        [header]="'Are you sure you want to delete this share?'"
      ></app-modal-header-center>
    </ng-template>
    <div class="info-modal__body">
      <span class="info-modal__body-content">
        Deleting the
        <span class="content-highlight">{{ itemData().name }}</span>
        share will
        <span class="content-highlight">delete the share from file shares view.</span>
      </span>
    </div>
  </app-generic-modal>
</ng-template>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';

import { DevelopmentType, ISortOption, ViewMode, ViewContainerDataType, RouteParam } from '@shared/_models';

import { RouteData } from '../../../_models/route-data';
import { selectRouteDataParam, selectRouteParam } from '../../../_root-store/router-store/selectors';

@Component({
  selector: 'app-view-container',
  templateUrl: './view-container.component.html',
  styleUrls: ['./view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContainerComponent {
  readonly ViewMode = ViewMode;
  @Input() developmentType!: DevelopmentType;
  @Input() listData: ViewContainerDataType[];
  @Input() listDataLoaded: boolean;
  @Input() sort: ISortOption;
  @Output() sortChange: EventEmitter<ISortOption> = new EventEmitter<ISortOption>();

  DevelopmentType = DevelopmentType;
  constructor(private readonly store: Store) {}

  developmentType$ = this.store.select(selectRouteDataParam(RouteData.DEVELOPMENT_TYPE));
  viewMode$ = this.store.select(selectRouteParam(RouteParam.VIEW_MODE));
  currentViewMode$ = combineLatest([this.developmentType$, this.viewMode$]).pipe(
    map(([developmentType, viewMode]) => {
      if (developmentType === DevelopmentType.project) {
        return ViewMode.grid;
      }
      return viewMode;
    })
  );
}

import { MemberRole } from '@shared/_models';

import { ProjectMember } from './project-member';

const memberRoleOrder = [MemberRole.owner, MemberRole.lead, MemberRole.editor, MemberRole.member, MemberRole.reader];

export function compareProjectMember(a: ProjectMember, b: ProjectMember): number {
  if (a.role === b.role) {
    return a.name.localeCompare(b.name);
  }

  return memberRoleOrder.indexOf(a.role) - memberRoleOrder.indexOf(b.role);
}

<div
  class="development-item-wrapper"
>
  @if( editMode() ){
    <app-box-controls-bar
      [showActionBtns]="false"
      [visible]="true"
      [hovered]="hoveredBoxIndex() === index()"
      class="development-item__handle"
    ></app-box-controls-bar>
  }
  <div class="development-item" [class.development-item--file-share]="itemtIsFileShare()">
    @if(developmentItemActions()){
      <app-more-actions
        [actions]="developmentItemActions()"
        [entity]="developmentItemEntity()"
        placement="left-top"
        class="me-1 development-item__actions"
      ></app-more-actions>
    }
    @if(developmentItemStatus()){
      <div class="development-item__status development-item__status--active">
        <div class="status-icon status-icon--{{ developmentItemStatus() }}"></div>
      </div>
    }
    <div data-testid="development-ro" class="development-item__body">
      <div class="development-item__header">
        <span class="development-item__oid"
        [class.gradient--ro]="developmentType() === DevelopmentType.researchObject"
        [class.gradient--exp]="developmentType() === DevelopmentType.experiment">
          {{ developmentItemId() }}
        </span>
      </div>
      @if( developmentItemName() ){
        <div
          class="development-item__text development-item__main-text text-break"
          ngbTooltip="{{ developmentItemName() }}"
          container="body"
          appOverflowContentPluginToNgbTooltip
        >
          <span class="development-item__name">
            {{ developmentItemName() }}
          </span>
        </div>
      }
      @if( developmentItemCategoryName() ){
        <span class="development-item__text development-item__sub-text text-truncate">
          {{ developmentItemCategoryName() }}
        </span>
      }
    </div>
     @if(elementName()){
       <div class="development-item-footer" [class.development-item-footer--file-share]="itemtIsFileShare()">
         <span ngbTooltip="{{ elementName() }}" appOverflowContentPluginToNgbTooltip class="development-item-footer__text">
          @if(itemtIsFileShare()){
            <div class="development-item-footer__icon"></div>
          }
           {{ elementName() }}
         </span>
       </div>
     }
  </div>
</div>

import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { CloseReason } from '@app/shared/_components/generic-modal/utils';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { DevelopmentItem, DevelopmentType, UpdateArea } from '@app/shared/_models';

import { BoxControlsBarComponent } from '../../../_components/box-control-bar/box-controls-bar.component';
import { MoreActionsComponent } from '../../../_components/more-actions/more-actions.component';
import { MenuAction } from '../../../_components/more-actions/utils';

@Component({
  selector: 'app-grid-element-development-item',
  standalone: true,
  imports: [NgbTooltip, MoreActionsComponent, BoxControlsBarComponent],
  templateUrl: './grid-element-development-item.component.html',
  styleUrls: ['./grid-element-development-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridElementDevelopmentItemComponent {
  developmentItemId = input<string>();
  developmentItemName = input<string>();
  developmentItemCategoryName = input<string>();
  developmentType = input<DevelopmentType>();
  developmentItemsLength = input<number>();
  developmentItemActions = input<MenuAction<void>[]>();
  developmentItemEntity = input<DevelopmentItem>();
  developmentItemStatus = input<string>();
  hoveredBoxIndex = input<number>();
  editMode = input<boolean>();
  elementId = input<string>();
  elementName = input<string>();
  itemtIsFileShare = input<boolean>();
  index = input<number>();
  unlink = output<void>();

  IconColors = IconColors;
  DevelopmentType = DevelopmentType;
  CloseReason = CloseReason;
  UpdateArea = UpdateArea;

  constructor() {}
}

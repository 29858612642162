<div class="files-upload-share-info">
  <a (click)="shareFiles.emit()">
    <img src="./assets/icons/share.svg" class="files-upload-share-info__icon" />
    <span class="mx-2"
      >Add share from another {{ project()?.development_type_names.research_objects }} or
      {{ project()?.development_type_names.experiments }}</span
    >
  </a>
  <div class="files-upload-share-info__tooltip-container" [ngbPopover]="popoverContent" popoverTitle="File sharing">
    <img src="./assets/icons/info_dark_2_icon.svg" />
    <ng-template #popoverContent>
      You can share a file by adding it from another {{ project()?.development_type_names.research_objects }}/{{
        project()?.development_type_names.experiments

      }}. A shared file can be changed and updated, which will result in changes in all shared locations. Shared files can be managed from
      the list view in {{ project()?.development_type_names.research_objects }}/{{
        project()?.development_type_names.experiments
      }}
      details.
    </ng-template>
  </div>
</div>

import { computed, Injectable, Signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { isUnique } from '@shared/_helpers';
import { FileUploadParams, ImageDto, FileDto, DevelopmentItem, DevelopmentItemFile } from '@shared/_models';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { ImageApiService } from '@shared/_services/image/image-api.service';

import { FileUploadStatus, FileCategory } from '../models';
import { ShareNameData } from './components/upload-cards/utils';
import { toFileUploadStatusFactory, getRemoveShareFactory, toDevelopmentItemFileDto } from './utils';

interface FileUploadModalState {
  data: FileUploadStatus[];
}

export const defaultFileUploadModalState: FileUploadModalState = {
  data: []
};

@Injectable()
export class FileUploadModalComponentStore extends ComponentStore<FileUploadModalState> {
  #fileCategory: FileCategory;
  #developmentItem: Signal<DevelopmentItem>;
  #params = computed(() => ({
    object_id: this.#developmentItem?.()?.id,
    development_type: this.#developmentItem?.()?.template.category.development_type
  }));
  #removeShare: (developmentItem: DevelopmentItem, status: FileUploadStatus) => Observable<void> = getRemoveShareFactory();

  constructor(
    private readonly imageApiService: ImageApiService,
    private readonly toastService: ToastService
  ) {
    super(defaultFileUploadModalState);
  }

  readonly #toFileUploadStatusFactory: (file: File, fileCategory: FileCategory, params: FileUploadParams) => FileUploadStatus =
    toFileUploadStatusFactory();

  readonly data$ = this.select(state => state.data);

  readonly setData = this.updater((state, data: FileUploadStatus[]) => ({ ...state, data }));
  readonly #updateDataItem = this.updater((state, update: { guid: string; item: Partial<FileUploadStatus> }) => ({
    ...state,
    data: state.data.map(item => (item.guid === update.guid ? { ...item, ...update.item } : item))
  }));
  readonly #removeDataItem = this.updater((state, guid: string) => ({ ...state, data: state.data.filter(item => item.guid !== guid) }));

  readonly onSelectedFiles = this.effect((data$: Observable<File[]>) => {
    return data$.pipe(
      withLatestFrom(this.data$),
      tap(([files, data]: [File[], FileUploadStatus[]]) => {
        const newFilesToUpload: FileUploadStatus[] = Array.from(files).map((file: File) =>
          this.#toFileUploadStatusFactory(file, this.#fileCategory, this.#params())
        );
        this.setData([...newFilesToUpload, ...data]);
      })
    );
  });

  readonly onDropedFiles = this.effect((data$: Observable<DataTransferItemList>) => {
    return data$.pipe(
      withLatestFrom(this.data$),
      tap(([dropedFiles, data]: [DataTransferItemList, FileUploadStatus[]]) => {
        const files: File[] = Array.from(dropedFiles).map((item: DataTransferItem) => item.getAsFile());
        const newFilesToUpload: FileUploadStatus[] = files.map((file: File) =>
          this.#toFileUploadStatusFactory(file, this.#fileCategory, this.#params())
        );
        this.setData([...newFilesToUpload, ...data]);
      })
    );
  });

  readonly removeFile = this.effect((data$: Observable<RemoveFileArgs>) => {
    return data$.pipe(
      tap(({ guid }: RemoveFileArgs) => {
        this.#updateDataItem({ guid, item: { uploadPending: true } });
      }),
      withLatestFrom(this.data$),
      switchMap(([{ fileId, guid, onSuccess }, data]: [RemoveFileArgs, FileUploadStatus[]]) => {
        const item = data.find(item => item.guid === guid);
        const deleteOperation: Observable<void | ImageDto> =
          this.#fileCategory === FileCategory.files
            ? this.#removeShare(this.#developmentItem(), item)
            : this.imageApiService.delete(fileId);

        return deleteOperation.pipe(
          tap(() => {
            onSuccess?.();
            this.#removeDataItem(guid);
          }),
          catchError(() => {
            this.#updateDataItem({ guid, item: { uploadPending: false } });

            return EMPTY;
          })
        );
      })
    );
  });

  readonly cancelUpload = this.effect((data$: Observable<string>) => {
    return data$.pipe(
      tap((guid: string) => {
        /* uploadHttpEvents$ gets unsubscribed in FileUploadCardComponent,
         * FileUploadCardComponent is used also in other components so this
         * unsubscribtion can't be moved here */
        this.#removeDataItem(guid);
      })
    );
  });

  readonly uploadStarted = this.effect((data$: Observable<string>) => {
    return data$.pipe(
      tap((guid: string) => {
        this.#updateDataItem({ guid, item: { uploadPending: true } });
      })
    );
  });

  readonly uploadSucceeded = this.effect(
    (
      data$: Observable<{
        dto: FileDto | ImageDto;
        guid: string;
        initiallyValid: boolean;
        onValidated: (event: DevelopmentItemFile) => void;
      }>
    ) => {
      return data$.pipe(
        withLatestFrom(this.data$),
        tap(
          ([{ dto, guid, initiallyValid, onValidated }, data]: [
            { dto: FileDto | ImageDto; guid: string; initiallyValid: boolean; onValidated: (event: DevelopmentItemFile) => void },
            FileUploadStatus[]
          ]) => {
            this.#updateDataItem({ guid, item: { uploadPending: false, dto } });
            const dataItem: FileUploadStatus = data.find(item => item.guid === guid);
            const files =
              this.#fileCategory === FileCategory.files
                ? this.#developmentItem().files
                : this.#developmentItem().images.map(toDevelopmentItemFileDto);
            const reservedValues = files.map(file => file.name);

            /* we propagate(onValidated) the event(save share) only if the file is unique,
             * and by adding && initiallyValid we prevent adding files/images
             * during editing name  */
            if (isUnique(reservedValues, dataItem.name) && initiallyValid) {
              onValidated({ name: dataItem.name, file: dto });
            }
          }
        )
      );
    }
  );

  readonly uploadFailed = this.effect((data$: Observable<string>) => {
    return data$.pipe(
      tap((guid: string) => {
        this.toastService.show('File upload failed', {
          header: 'File upload',
          type: 'danger'
        });
        this.#updateDataItem({ guid, item: { uploadPending: false, uploadFailed: true } });
      })
    );
  });

  readonly shareNameChanged = this.effect((data$: Observable<ShareNameData>) => {
    return data$.pipe(
      tap(({ guid, shareName }: ShareNameData) => {
        this.#updateDataItem({ guid, item: { name: shareName } });
      })
    );
  });

  readonly onShareAdding = this.effect((data$: Observable<FileUploadStatus>) => {
    return data$.pipe(
      withLatestFrom(this.data$),
      tap(([file, data]: [FileUploadStatus, FileUploadStatus[]]) => {
        this.setData([file, ...data]);
      })
    );
  });

  readonly onSharesAdded = this.effect((data$: Observable<FileUploadStatus[]>) => {
    return data$.pipe(
      withLatestFrom(this.data$),
      tap(([files, data]: [FileUploadStatus[], FileUploadStatus[]]) => {
        this.setData([...files, ...data]);
      })
    );
  });

  setup(fileCategory: FileCategory, developmentItem: Signal<DevelopmentItem>) {
    this.#fileCategory = fileCategory;
    this.#developmentItem = developmentItem;
  }
}

type RemoveFileArgs = { fileId: string; guid: string; onSuccess?: () => void };

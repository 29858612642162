<div class="member-element" [class.member-element--even]="index() % 2 === 0">
  <span class="member-element__badge member-element__badge--{{ member()?.roleAfterEdit | lowercase }}">
    <span class="member-element__badge__text">{{ badge() }}</span>
  </span>
  <app-user-avatar [imageBase64]="avatar()?.base64" avatarSize="1.3rem" data-testid="member-avatar"></app-user-avatar>
  <div class="member-element__info">
    <span class="member-element__info__name"> {{ member()?.user.name }} {{ member()?.user.surname }} </span>
    <div class="d-flex align-items-center member-element__dropdown-container">
      @if (memberIsOwner() || (memberIsLead() && projectProxy().isLead)) {
        <span>{{ memberIsOwner() ? 'Owner' : 'Lead' }}</span>
      } @else {
        <app-dropdown
          class="d-flex"
          [items]="editableRoles()"
          [selectedValue]="member()?.roleAfterEdit"
          (selected)="onEditMemberRole($event)">
        </app-dropdown>
      }
      @if (infoPopover()) {
        <app-info-popover class="ms-1 member-element__info-icon" [data]="infoPopover()"></app-info-popover>
      }
    </div>
  </div>
  <app-icon
    *ngIf="projectProxy()?.canRemoveMember(member()) && !memberIsOwner()"
    class="member-element__delete-btn"
    [width]="'0.6rem'"
    [height]="'0.7rem'"
    [fileName]="'trash_black_icon.svg'"
    [color]="IconColors.invalidRed"
    (click)="memberDelete.emit(member().user.id)"></app-icon>
</div>

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, Observable } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ExperimentsApiService } from '@app/shared/_services/experiment';
import { ResearchObjectsApiService } from '@app/shared/_services/research-object';
import { DevelopmentItemLinkedFilesShortDto, LinkedFileDto, DevelopmentType } from '@shared/_models';
import { notifyAboutError } from '@shared/_root-store/app-store/app.actions';
import { FileApiService } from '@shared/_services/file/file-api.service';

interface FileSharingModalState {
  file: LinkedFileDto;
  linkedToDevelopmentItems: DevelopmentItemLinkedFilesShortDto[];
  pending: boolean;
}

export const defaultFileSharingModalState: FileSharingModalState = {
  file: undefined,
  linkedToDevelopmentItems: undefined,
  pending: false
};

@Injectable()
export class FileSharingModalComponentStore extends ComponentStore<FileSharingModalState> {
  constructor(
    private fileApiService: FileApiService,
    private researchObjectsApiService: ResearchObjectsApiService,
    private experimentsApiService: ExperimentsApiService,
    private store: Store,
    private modal: NgbModal
  ) {
    super(defaultFileSharingModalState);
  }

  setup(file: LinkedFileDto) {
    this.setFile(file);
  }

  readonly file$ = this.select(state => state.file);
  readonly linkedToDevelopmentItems$ = this.select(state => state.linkedToDevelopmentItems);
  readonly pending$ = this.select(state => state.pending);

  readonly setFile = this.updater((state, file: LinkedFileDto) => ({ ...state, file }));
  readonly setLinkedToDevelopmentItems = this.updater((state, linkedToDevelopmentItems: DevelopmentItemLinkedFilesShortDto[]) => ({
    ...state,
    linkedToDevelopmentItems
  }));
  readonly setPending = this.updater((state, pending: boolean) => ({ ...state, pending }));

  readonly loadLinkedToDevelopmentItems = this.effect((data$: Observable<void>) => {
    return data$.pipe(
      tap(() => this.setPending(true)),
      withLatestFrom(this.file$),
      switchMap(([_, file]) => {
        return this.fileApiService.getSharedDevelopmentItems(file.id).pipe(
          tap((response: DevelopmentItemLinkedFilesShortDto[]) => {
            this.setLinkedToDevelopmentItems(response);
            this.setPending(false);
          }),
          catchError(errorResponse => {
            this.setPending(false);
            this.store.dispatch(notifyAboutError({ errorResponse }));

            return EMPTY;
          })
        );
      })
    );
  });

  readonly link = this.effect((data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; shareName: string }>) =>
    data$.pipe(
      withLatestFrom(this.file$),
      tap(
        ([{ developmentItem, shareName }, file]: [
          { developmentItem: DevelopmentItemLinkedFilesShortDto; shareName: string },
          LinkedFileDto
        ]) => {
          this.#addShare({ developmentItem, file, shareName });
        }
      )
    )
  );

  readonly unlink = this.effect((data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; onSuccess(): void }>) =>
    data$.pipe(
      withLatestFrom(this.file$),
      tap(([{ developmentItem, onSuccess }, file]: [{ developmentItem: DevelopmentItemLinkedFilesShortDto; onSuccess }, LinkedFileDto]) => {
        this.#removeShares({ developmentItem, shareId: file.id, onSuccess });
      })
    )
  );

  readonly updateShareName = this.effect((data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; shareName: string }>) =>
    data$.pipe(
      tap(({ developmentItem, shareName }) => {
        this.#updateShare({ developmentItem, shareName });
      })
    )
  );

  readonly updateFile = this.effect((data$: Observable<LinkedFileDto>) =>
    data$.pipe(
      switchMap(file => {
        return this.fileApiService.update(file).pipe(
          tap(() => {
            this.setFile(file);
          }),
          catchError(errorResponse => {
            this.store.dispatch(notifyAboutError({ errorResponse }));

            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteFile = this.effect((data$: Observable<{ file: LinkedFileDto; onSuccess: () => void }>) =>
    data$.pipe(
      switchMap(({ file, onSuccess }) => {
        return this.fileApiService.delete(file.id).pipe(
          tap(() => {
            onSuccess();
          }),
          catchError(errorResponse => {
            this.store.dispatch(notifyAboutError({ errorResponse }));

            return EMPTY;
          })
        );
      })
    )
  );

  readonly #addShare = this.effect(
    (data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; file: LinkedFileDto; shareName: string }>) =>
      data$.pipe(
        withLatestFrom(this.linkedToDevelopmentItems$),
        switchMap(([{ developmentItem, file, shareName }, linkedToDevelopmentItems]) => {
          const addShare: (developmentItemId: string, share: { file_id: string; name: string }) => Observable<void> =
            developmentItem.development_type === DevelopmentType.researchObject
              ? this.researchObjectsApiService.addShare.bind(this.researchObjectsApiService)
              : this.experimentsApiService.addShare.bind(this.experimentsApiService);
          return addShare(developmentItem.id, { file_id: file.id, name: shareName }).pipe(
            tap(() => {
              this.setLinkedToDevelopmentItems([...linkedToDevelopmentItems, developmentItem]);
            }),
            catchError(errorResponse => {
              this.store.dispatch(notifyAboutError({ errorResponse }));
              return EMPTY;
            })
          );
        })
      )
  );

  readonly #removeShares = this.effect(
    (data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; shareId: string; onSuccess(): void }>) =>
      data$.pipe(
        withLatestFrom(this.linkedToDevelopmentItems$),
        switchMap(([{ developmentItem, shareId, onSuccess }, linkedToDevelopmentItems]) => {
          const removeShares: (developmentItemId: string, shareIds: string[]) => Observable<void> =
            developmentItem.development_type === DevelopmentType.researchObject
              ? this.researchObjectsApiService.removeShares.bind(this.researchObjectsApiService)
              : this.experimentsApiService.removeShares.bind(this.experimentsApiService);
          return removeShares(developmentItem.id, [shareId]).pipe(
            tap(() => {
              this.setLinkedToDevelopmentItems(linkedToDevelopmentItems.filter(item => item.id !== developmentItem.id));
              onSuccess();
            }),
            catchError(errorResponse => {
              this.store.dispatch(notifyAboutError({ errorResponse }));
              return EMPTY;
            })
          );
        })
      )
  );

  readonly #updateShare = this.effect((data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; shareName: string }>) =>
    data$.pipe(
      withLatestFrom(this.file$, this.linkedToDevelopmentItems$),
      switchMap(([{ developmentItem, shareName }, file, linkedToDevelopmentItems]) => {
        const share = { file_id: file.id, name: shareName };
        const updateShare: (developmentItemId: string, share: { file_id: string; name: string }) => Observable<void> =
          developmentItem.development_type === DevelopmentType.researchObject
            ? this.researchObjectsApiService.updateShare.bind(this.researchObjectsApiService)
            : this.experimentsApiService.updateShare.bind(this.experimentsApiService);
        return updateShare(developmentItem.id, share).pipe(
          tap(() => {
            this.setLinkedToDevelopmentItems(
              linkedToDevelopmentItems.map(item =>
                item.id === developmentItem.id
                  ? { ...item, files: item.files.map(file => (file.file_id === share.file_id ? { ...file, name: share.name } : file)) }
                  : item
              )
            );
          }),
          catchError(errorResponse => {
            this.store.dispatch(notifyAboutError({ errorResponse }));
            return EMPTY;
          })
        );
      })
    )
  );
}

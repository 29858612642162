<div *ngIf="!fileUploadSuccess" class="category-select__control">
  <label class="category-select__label">Category you want to import to</label>
  <div [ngClass]="{ 'category-select__control--disabled': fileUploadStatuses[0]?.uploadPending }">
    <app-tree-select
      [disabled]="fileUploadStatuses[0]?.uploadPending"
      [form]="form"
      [controlName]="FORM_FIELDS.SELECTED_CATEGORY"
      [items]="categoriesSelectItems$ | async"
      ngDefaultControl>
    </app-tree-select>
  </div>
</div>
<div *ngIf="form.get(FORM_FIELDS.SELECTED_CATEGORY).value" class="import-modal-container">
  <div class="import-modal-text">
    {{ mainCategoryName() }} can be imported from a properly prepared Excel file (<a
      class="import-modal-sample"
      (click)="downloadSampleFile()"
      >download sample file</a
    >).
  </div>
</div>
<div *ngIf="!hideBrowseFile && form.get(FORM_FIELDS.SELECTED_CATEGORY).value">
  <input type="file" class="d-none lg-files-modal__input" id="fileInput" (change)="onFileSelected($event, true)" />
  <label class="import-modal-browse-container browse-container-background--{{ developmentType() }}" for="fileInput">
    <i class="import-modal-browse--icon"></i>
    <span class="import-modal-browse-text">To import browse excel file</span>
  </label>
</div>
<div *ngFor="let fileState of fileUploadStatuses; let i = index; trackBy: trackById">
  <div
    [ngClass]="{
      'import-modal-fail': fileUploadFail(),
      'import-modal-upload-container': !fileUploadSuccess,
      'import-modal-upload-analyzed': fileAnalysisCompleted && fileUploadStatuses[0]?.uploadPending === false
    }">
    <app-file-upload-card
      *ngIf="!fileUploadSuccess"
      [uploadExcel]="true"
      [analyzeExcel]="fileState?.dryRun"
      [fileName]="fileState?.fileToUpload.name"
      [fileSize]="fileState?.fileToUpload.size"
      [fileExtension]="fileState?.fileToUpload.type"
      [uploadEvents$]="fileState?.uploadHttpEvents$"
      (uploadCancel)="onUploadCancel()"
      (uploadSuccess)="onUploadSuccess($event, i)"
      (analyzeSuccess)="onAnalyzeSuccess($event, i)"
      (uploadFail)="onUploadFail(i)"
      (uploadInProgress)="onUploadInProgress(i)"
      (remove)="onUploadCancel()"
    ></app-file-upload-card>
  </div>
</div>
<div *ngIf="fileUploadSuccess">
  <label class="import-modal-browse-container browse-container-background--success" for="fileInput">
    <i class="import-modal-success--icon"></i>
    <span class="import-modal-browse-text">
      After import, the {{ mainCategoryName() }} are
      <ng-container *ngFor="let part of getAnalisysSummaryText(); let i = index">
        <span>{{ part.text }}</span>
        <span *ngIf="i < getAnalisysSummaryText().length - 1">,&nbsp;</span>
      </ng-container>
      <span *ngIf="getAnalisysSummaryText().length > 0">.</span>
    </span>
  </label>
</div>
<div *ngIf="fileUploadFail()" class="import-moda-fail-message">
  Import error - cant import data wrong file structure, or nothing to import
</div>
<div *ngIf="fileUploadStatuses[0]?.uploadPending === false && !fileUploadSuccess && !fileUploadFail()">
  <div *ngIf="fileAnalysisCompleted" class="import-modal-analysis-container">
    <div class="import-modal-analysis-label">
      The analyzed file contains {{ getTotalObjects() }} {{ mainCategoryName() }}:
      <ng-container *ngFor="let part of getAnalisysSummaryText(); let i = index">
        <span [ngClass]="part.class">{{ part.text }}</span>
        <span *ngIf="i < getAnalisysSummaryText().length - 1">,&nbsp;</span>
      </ng-container>
      <span *ngIf="getAnalisysSummaryText().length > 0">.</span>
    </div>
  </div>
  <div *ngIf="importExcel?.error_list?.length > 0" class="import-modal-errors">
    <div class="errors-container">
      <span *ngIf="importExcel.error_rows > 0" class="errors-label"> {{ mainCategoryName() }} with errors </span>
      <span *ngIf="importExcel.error_rows > 15" class="errors-label-above">- showing first 15</span>
    </div>
    <app-import-errors [importErrors]="importExcel.error_list"></app-import-errors>
  </div>
</div>
<div
  class="import-modal-footer"
  [ngClass]="{ 'import-modal-footer--success': fileUploadSuccess }"
  *ngIf="
    (fileUploadStatuses[0]?.uploadPending === false && fileAnalysisCompleted && !fileUploadFail()) ||
    (fileUploadStatuses[0]?.uploadPending === false && fileUploadSuccess)
  ">
  <app-button class="import-modal-button" [class]="getClassStyle(CloseReason.CLOSE)" (click)="onClose(CloseReason.CLOSE)">
    {{ fileUploadSuccess ? 'Download summary' : 'Download analysis summary' }}
  </app-button>
  <app-button class="import-modal-button" [class]="getClassStyle(CloseReason.ACCEPT)" ngbAutofocus (click)="onClose(CloseReason.ACCEPT)">
    {{ fileUploadSuccess ? 'Close' : 'Import analyzed file' }}
  </app-button>
</div>

<div class="upload-cards">
  @for (fileState of fileUploadStatusesVM(); track fileState.guid; let i = $index) {
    <app-file-upload-card
      [fileName]="fileState.fileToUpload.name"
      [fileSize]="fileState.fileToUpload.size"
      [fileExtension]="fileState.fileToUpload.type"
      [uploadEvents$]="fileState.uploadHttpEvents$"
      [disabled]="anyUploadPending()"
      [name]="fileState.name"
      [reservedNames]="reservedNames()"
      [attached]="fileState.attached"
      [isShare]="fileState.share"
      [fileUploadFailed]="fileState.uploadFailed"
      [fileCategory]="fileCategory()"
      [developmentType]="developmentType()"
      (uploadCancel)="cancel.emit(fileState.guid)"
      (uploadSuccess)="succeeded.emit({ dto: $event, guid: fileState.guid, initiallyValid: fileState.initiallyValid })"
      (uploadInProgress)="inProgress.emit(fileState.guid)"
      (uploadFail)="failed.emit(fileState.guid)"
      (remove)="remove.emit({ fileId: $event, guid: fileState.guid })"
      (shareNameChanged)="shareNameChanged.emit({ shareName: $event, guid: fileState.guid })"
      (create)="create.emit(fileState.guid)">
    </app-file-upload-card>
  }
</div>

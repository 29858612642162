import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeSubtype',
  standalone: true
})
export class MimeSubtypePipe implements PipeTransform {
  /**
   * Mapping of MIME types to file extensions
   */
  readonly #mimeToExtension: Record<string, string> = {
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/gif': 'gif',
    'text/plain': 'txt',
    'text/html': 'html'
    // Add more MIME types as needed
  };

  /**
   * Transform MIME type to file extension or return MIME subtype as fallback
   *
   * @param mimeFormat - The MIME type of the file
   * @returns File extension (e.g., .pdf) or MIME subtype if no mapping exists
   */
  transform(mimeFormat: string): string {
    if (mimeFormat in this.#mimeToExtension) {
      return this.#mimeToExtension[mimeFormat];
    }

    const parsedMimeFormat = mimeFormat?.split('/');
    if (parsedMimeFormat?.length === 2) {
      return parsedMimeFormat[1]; // Return MIME subtype as fallback
    }

    return '?';
  }
}

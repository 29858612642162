import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs';

import { InputWithClearComponent } from '@app/shared/_components';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderLeftComponent } from '@app/shared/_components/modal-header-left/modal-header-left.component';
import { DevelopmentType } from '@app/shared/_models';
import { CloseReason } from '@shared/_components/generic-modal/utils';

import { InputWithValidationComponent } from '../../../_components/inputs/input-with-validation/input-with-validation.component';
import { CategoriesStoreService } from '../../../_root-store/categories-store/categories-store.service';
import { duplicateStringValidator } from '../../../_validators';
import { EditorComponent } from '../../../dynamic-form/components/editor/editor.component';

@Component({
  selector: 'app-file-share-edit-name-modal',
  standalone: true,
  templateUrl: './file-share-edit-name-modal.component.html',
  styleUrls: ['./file-share-edit-name-modal.component.scss'],
  imports: [
    InputWithClearComponent,
    ReactiveFormsModule,
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    InputWithValidationComponent,
    CommonModule,
    EditorComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileShareEditNameModalComponent implements OnInit {
  fileName = input.required<string>();
  fileDescription = input<string>();
  developmentItemName = input<string>();
  sourceFile = input<boolean>();
  shareNames = input<string[]>();
  checkForInitialError = input<boolean>();
  developmentType = input<DevelopmentType>();
  closeModal = output<void>();
  saveBtnClick = output<string>();

  #categoryId$ = this.activatedRoute.paramMap.pipe(map(paramMap => paramMap.get('categoryId')));
  category$ = this.#categoryId$.pipe(switchMap(categoryId => this.categoriesStoreService.selectCategoryById(categoryId)));

  readonly DevelopmentType = DevelopmentType;
  form: FormGroup;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly categoriesStoreService: CategoriesStoreService
  ) {}

  ngOnInit() {
    this.createFormControls();
    if (this.checkForInitialError()) {
      this.form.markAllAsTouched();
    }
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.ACCEPT) {
      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }
      this.saveBtnClick.emit(this.form.value);
    }
    this.closeModal.emit();
  }

  private createFormControls() {
    this.form = new FormGroup({
      name: new FormControl(this.fileName(), [
        Validators.required,
        Validators.maxLength(255),
        ...(this.sourceFile() ? [] : [duplicateStringValidator(this.shareNames)])
      ]),
      description: new FormControl(this.fileDescription() || null, [Validators.maxLength(2000)])
    });
  }

  onClearName() {
    this.form.get('name').setValue('');
  }
}

<div class="form-group">
  <div class="input-wrapper" [class.input-wrapper--with-icon]="iconPath || icon()">
    @if (iconPath) {
      <i class="{{ iconPath }}"></i>
    }
    @if (icon()) {
      <app-icon [fileName]="icon().name" [color]="icon().color" [width]="icon().width" [height]="icon().height"></app-icon>
    }
    <input
      [value]="ngModel()"
      (input)="onValueChange($event.target.value)"
      class="form-control input"
      [type]="type"
      [class.input--invalid]="hasError"
      [placeholder]="placeholder ?? ''" />
    <button type="button" (click)="onClear()" *ngIf="ngModel()" [ngClass]="{ 'btn-clear-invite': iconPath }" class="btn btn-clear"></button>
  </div>
</div>

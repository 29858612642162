import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
  OnInit,
  signal,
  inject,
  DestroyRef,
  output,
  computed
} from '@angular/core';
import { toObservable, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { EMPTY, Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { FileWithoutExtensionPipe } from '@app/shared/pipes';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { InputWithClearComponent } from '@shared/_components/inputs/input-with-clear/input-with-clear.component';
import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';
import { DevelopmentType, FileDto, ImageDto, ImportExcel } from '@shared/_models';
import { FileThumbComponent } from '@shared/_modules/file/file-thumb/file-thumb.component';
import { FileThumbRotateComponent } from '@shared/_modules/file/file-thumb-rotate/file-thumb-rotate.component';
import { FileCategory } from '@shared/_modules/file/models/file-category';
import { FormatFileSizePipe } from '@shared/_modules/file/pipes/format-file-size.pipe';
import { MimeSubtypePipe } from '@shared/_modules/file/pipes/mime-subtype.pipe';
import { duplicateStringValidator } from '@shared/_validators';
import { FieldConfig } from '@shared/dynamic-form/models';

import { FileService } from '../file.service';
import { getProgress, isNothingToImport } from './utils';
import { MoreActionsComponent } from '../../../_components/more-actions/more-actions.component';
import { MenuAction } from '../../../_components/more-actions/utils';

@Component({
  selector: 'app-file-upload-card',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FileWithoutExtensionPipe,
    FormatFileSizePipe,
    MimeSubtypePipe,
    FileThumbComponent,
    FileThumbRotateComponent,
    IconComponent,
    InputWithValidationComponent,
    InputWithClearComponent,
    ButtonComponent,
    MoreActionsComponent
  ],
  templateUrl: './file-upload-card.component.html',
  styleUrls: ['./file-upload-card.component.scss']
  // TODO: figure out why tests are failing when using OnPush
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadCardComponent implements OnInit {
  @Input() uploadExcel: boolean;
  @Input() analyzeExcel: boolean;
  @Input() fileSharing: boolean; // sliding from right file sharing "modal"
  @Input() fileUploadInProgress: boolean;
  @Input() fileSize: number;
  @Input() fileExtension: string;

  @Input() set uploadEvents$(value: Observable<HttpEvent<object>>) {
    this.#onuploadEvents$Change(value);
  }
  #uploadEvents$: Observable<HttpEvent<object>>;

  fileName = input.required<string>();
  disabled = input<boolean>();
  name = input<string>(); // share name for files, file name for images
  reservedNames = input<string[]>([]);
  attached = input<boolean>(false); // file is attached to RO/EX
  isShare = input<boolean>(false);
  fileUploadFailed = input<boolean>(false);
  fileCategory = input<FileCategory>();
  developmentType = input<DevelopmentType>();
  @Output() uploadCancel: EventEmitter<void> = new EventEmitter();
  @Output() uploadSuccess: EventEmitter<FileDto | ImageDto | ImportExcel> = new EventEmitter();
  @Output() analyzeSuccess: EventEmitter<ImportExcel> = new EventEmitter();
  @Output() uploadFail: EventEmitter<void> = new EventEmitter();
  @Output() uploadInProgress: EventEmitter<void> = new EventEmitter<void>();
  @Output() remove: EventEmitter<string> = new EventEmitter();
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  shareNameChanged = output<string>();
  create = output<void>();
  delete = output<void>();
  edit = output<void>();

  withError = computed<boolean>(() => this.fileUploadFailed() || this.controlInvalid());
  shareNames$ = toObservable<string[]>(this.reservedNames);
  fileNameDisplay = computed(() =>
    this.fileCategory() === FileCategory.images && this.fileId() && !this.showShareInput() ? this.name() : this.fileName()
  );
  control: FormControl;
  controlInvalid = signal<boolean>(false);
  showShareInput = signal<boolean>(false);
  fileUploadProgress: number = 0;
  uploadSub: Subscription;
  fileId = signal<string>(null);
  failureMessage: string;
  fileUploadFail: boolean = false;
  excelAnalyzed: boolean = false;
  fileUpdateComplete: boolean = false;
  editNameInfo = computed<{
    header: string;
    config: FieldConfig;
    placeholder: string;
    placeholderIcon: string;
    buttonClass: string;
    buttonLabel: string;
  }>(() => {
    const buttonClass = this.developmentType() ? `${this.developmentType()}-primary w-100 h-30` : 'general-primary w-100 h-30';

    if (this.fileCategory() === FileCategory.files)
      return {
        header: 'Share options',
        config: { label: 'Share name', name: 'share name' } as FieldConfig,
        placeholder: 'Share name',
        placeholderIcon: 'share.svg',
        buttonClass,
        buttonLabel: 'Create share'
      };

    // FileCategory.images;
    return {
      header: 'Image options',
      config: { label: 'Image name', name: 'image name' } as FieldConfig,
      placeholder: 'Image name',
      placeholderIcon: 'images_placeholder_dark_icon.svg',
      buttonClass,
      buttonLabel: 'Save image'
    };
  });
  sourceFileActions: MenuAction<void>[] = [
    {
      callback: () => this.edit.emit(),
      label: 'Edit name or description',
      icon: { fileName: 'pen_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.7rem' }
    },
    null,
    {
      callback: () => {
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
          fileInput.click();
          fileInput.onchange = (event: Event) => this.onFileSelected(event);
        }
      },
      label: 'Change the file and update it for shares',
      icon: { fileName: 'import_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.7rem' }
    },
    null,
    {
      callback: () => this.delete.emit(),
      label: 'Delete source file',
      icon: { fileName: 'trash_3_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.8rem' }
    }
  ];

  readonly #destroyRef = inject(DestroyRef);
  readonly IconColors = IconColors;
  readonly FileCategory = FileCategory;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly fileService: FileService
  ) {}

  ngOnInit() {
    this.#setupControl();
  }

  onCancelBtnClick(): void {
    this.uploadSub?.unsubscribe();
    this.uploadCancel.emit();
  }

  onRemoveBtnClick(): void {
    this.remove.emit(this.fileId());
  }

  getFileExtention(fileName: string): string {
    return this.fileService.extractFileExtensionFromFileName(fileName);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) return;

    this.fileSelected.emit(input.files[0]);
  }

  #onuploadEvents$Change(value: Observable<HttpEvent<any>>) {
    if (value === this.#uploadEvents$) return;

    this.uploadSub?.unsubscribe();
    this.#uploadEvents$ = value;
    this.#setup();
  }

  #setup() {
    this.uploadSub = this.#uploadEvents$
      ?.pipe(
        catchError((error: HttpErrorResponse) => {
          this.fileUploadFail = true;
          this.failureMessage = 'Upload failed';
          this.uploadFail.emit();

          if (error.status >= 500 && error.status < 600) {
            return throwError(() => error);
          }

          return EMPTY;
        })
      )
      .subscribe((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Sent) {
          this.uploadInProgress.emit();
        }

        if (event.type === HttpEventType.UploadProgress) {
          this.fileUploadProgress = getProgress(event.loaded, event.total);
          this.changeDetector.markForCheck();
        }

        if (event.type === HttpEventType.Response && (event.status === HttpStatusCode.Created || event.status === HttpStatusCode.Ok)) {
          this.fileId.set(event.body.id); // undefined for ImportExcel
          this.changeDetector.markForCheck();

          if (this.fileSharing) {
            this.fileUpdateComplete = true;
          }

          if (this.analyzeExcel) {
            if (isNothingToImport(event.body)) {
              this.fileUploadFail = true;
              this.failureMessage = 'Upload failed';
              this.uploadFail.emit();
            } else {
              this.excelAnalyzed = true;
              this.analyzeSuccess.emit(event.body);
            }

            return;
          }

          this.uploadSuccess.emit(event.body);
        }
      });
  }

  #setupControl() {
    if (this.fileSharing || this.uploadExcel) return;

    const name = this.fileCategory() === FileCategory.files ? this.name() : this.fileName();
    this.control = new FormControl(name ?? '', [Validators.required, duplicateStringValidator(this.reservedNames)]);
    this.control.markAsTouched();

    this.shareNames$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      if (!this.attached() && !this.fileUploadFailed()) {
        this.control.updateValueAndValidity();
      }

      if ((this.showShareInput() && !this.attached()) || this.fileUploadFailed()) return;

      this.showShareInput.set(this.control.invalid);
    });
    this.control.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(value => {
      this.shareNameChanged.emit(value);
    });
    this.control.statusChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(status => {
      this.controlInvalid.set(status === 'INVALID');
    });
  }
}

import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output, Signal } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { MoreActionsComponent } from '@shared/_components/more-actions/more-actions.component';
import { MenuAction } from '@shared/_components/more-actions/utils';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { MemberRole, ProjectDto, ProjectProxy, DevelopmentType } from '@shared/_models';
import { GridItemStatusComponent } from '@shared/_modules/grid/grid-item-status/grid-item-status.component';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';
import { SlicePipe } from '@shared/pipes/slice.pipe';

import { ProjectMember, compareProjectMember } from './utils';

@Component({
  selector: 'app-project-grid-card',
  standalone: true,
  imports: [
    GridItemStatusComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbTooltip,
    DatePipe,
    UserAvatarComponent,
    NgForOf,
    AsyncPipe,
    NgIf,
    NgClass,
    SlicePipe,
    IconComponent,
    MoreActionsComponent
  ],
  templateUrl: './project-grid-card.component.html',
  styleUrls: ['./project-grid-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectGridCardComponent {
  project = input.required<ProjectDto>();
  projectProxy = input.required<ProjectProxy>();
  @Output() edit = new EventEmitter<void>();
  @Output() leave = new EventEmitter<void>();
  @Output() manageMembers = new EventEmitter<void>();
  @Output() join = new EventEmitter<void>();
  @Output() openProject = new EventEmitter<void>();

  projectAllMembers = computed(() => {
    const project = this.project();
    const members = project.members.map(member => ({ ...member.user, role: MemberRole.member }));
    const leads = project.leads.map(lead => ({ ...lead, role: MemberRole.lead }));
    const owner = { ...project.user_crt, role: MemberRole.owner };

    return members
      .concat(leads)
      .concat([owner])
      .map(member => ({ ...member, avatar$: this.store.select(selectAvatarForId(member.id)) }));
  });
  daysSinceProjectStart = computed<number>(() => {
    const earlierDate = new Date(this.project().date_created);
    const today = new Date();
    const differenceInMilliseconds = today.getTime() - earlierDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    const differenceInFullDays = Math.ceil(differenceInDays);

    return differenceInFullDays;
  });
  projectOwnerAndLeads: Signal<ProjectMember[]> = computed(() => {
    return this.projectAllMembers()
      .filter(member => [MemberRole.owner, MemberRole.lead].includes(member.role))
      .sort(compareProjectMember);
  });
  hiddenResearchObjects = computed<boolean>(() => this.project().hidden_development_types.includes(DevelopmentType.researchObject));
  hiddenExperiments = computed<boolean>(() => this.project().hidden_development_types.includes(DevelopmentType.experiment));
  moreActions = computed<MenuAction<void>[]>(() => {
    const isLeadOrOwner = this.projectProxy().isLead || this.projectProxy().isOwner;

    return [
      ...(isLeadOrOwner
        ? [
            {
              callback: () => this.edit.emit(),
              label: 'edit'
            },
            {
              callback: () => this.manageMembers.emit(),
              label: 'manage members'
            }
          ]
        : []),
      ...(this.projectProxy().isLead ? [null] : []),
      ...(!this.projectProxy().isOwner
        ? [
            {
              callback: () => this.leave.emit(),
              label: 'leave project',
              highlightRed: true
            }
          ]
        : [])
    ];
  });

  readonly IconColors = IconColors;

  constructor(private store: Store) {}

  onCardClick() {
    if (!this.projectProxy().belongsToProject) {
      this.join.emit();
    } else {
      this.openProject.emit();
    }
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, viewChild, signal, computed } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { trackById } from '@shared/_helpers';
import { DevelopmentItem, LinkedFileDto, SkeletonType } from '@shared/_models';
import { FileSharingModalComponent } from '@shared/_modules/file/file-sharing-modal/file-sharing-modal.component';
import { SkeletonDirective } from '@shared/_modules/skeletons/skeleton.directive';
import { FileTableItemComponent } from '@shared/_modules/table/file-table-item/file-table-item.component';

@Component({
  selector: 'app-file-table-list',
  standalone: true,
  imports: [CommonModule, FileTableItemComponent, SkeletonDirective, FileSharingModalComponent],
  templateUrl: './file-table-list.component.html',
  styleUrls: ['./file-table-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTableListComponent {
  listDataLoaded = input.required<boolean>();
  files = input.required<LinkedFileDto[]>();
  selected = input.required<LinkedFileDto[]>();
  developmentItem = input<DevelopmentItem>();
  select = output<string[]>();
  unselect = output<string[]>();
  loadFiles = output<boolean>();
  downloadFile = output<string>();
  deleteShare = output<string>();
  editName = output<{ fileId: string; shareName: string }>();
  fileSharingModal = viewChild('fileSharingModal');

  sharedFile = computed<LinkedFileDto>(() => this.files().find(file => file.id === this.#sharedFileId()));
  #sharedFileId = signal<string>(null);
  selectedIds = computed(() => this.selected().map(file => file.id));
  allSelected = computed(() => this.files().every(file => this.selectedIds().includes(file.id)));

  readonly trackById = trackById;
  readonly SkeletonType = SkeletonType;

  constructor(private readonly ngbModal: NgbModal) {}

  toggleAllFilesSelection(event) {
    const ids = this.files().map(file => file.id);

    if (event.target.checked) {
      this.select.emit(ids);
    } else {
      this.unselect.emit(ids);
    }
  }

  onSelectHandler(selectedId: string) {
    this.select.emit([selectedId]);
  }

  onUnselectHandler(unselectedId: string) {
    this.unselect.emit([unselectedId]);
  }

  openFileSharingModal(fileId: string) {
    this.#sharedFileId.set(fileId);
    this.ngbModal.open(this.fileSharingModal(), {
      backdrop: false,
      animation: false,
      windowClass: 'no-border'
    });
  }

  onLoadFiles() {
    this.loadFiles.emit(false);
  }

  onClose(modal: NgbModalRef, reloadDevelopmentItem: boolean) {
    modal.close();
    this.loadFiles.emit(reloadDevelopmentItem);
  }

  onDownloadFile(fileId: string) {
    this.downloadFile.emit(fileId);
  }

  onDeleteShare(fileId: string) {
    this.deleteShare.emit(fileId);
  }

  onEditName(fileId: string, shareName: string) {
    this.editName.emit({ fileId: fileId, shareName: shareName });
  }
}

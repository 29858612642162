<app-item-details-section
  [sectionName]="areaName()"
  [sectionType]="SectionType.IMAGES"
  [showAddMoreBtn]="editable"
  [showEditBtn]="editable"
  [activeArea]="activeArea()"
  (addMore)="addImageBtnClick()"
  (edit)="onEdit()">
  <ng-container *ngIf="activeArea()" header-elements>
    <button
      data-testid="tab"
      *ngFor="let section of displaySections(); trackBy: trackById"
      class="tab"
      (click)="changeTab(section.id)"
      [ngClass]="{ 'tab--active': sectionIdSignal() === section.id }">
      {{ section.name }}
    </button>
  </ng-container>

  <div
    data-testid="images-carousel"
    class="images"
    [ngClass]="{
      'images--research-object': developmentType === DevelopmentType.researchObject,
      'images--experiment': developmentType === DevelopmentType.experiment
    }">
    <ngb-carousel
      data-testid="images-carousel-slides"
      [wrap]="false"
      [showNavigationArrows]="false"
      #ngbCarousel
      *ngIf="images?.length > 0; else noImage"
      [interval]="100000000000000000000"
      class="carousel--pagination-on-hover">
      <ng-container *ngFor="let image of images">
        <ng-template ngbSlide>
          <div class="images__wrapper" [class.images__wrapper--main]="image.id === (entity$ | async)?.main_image_id">
            <ng-container *ngTemplateOutlet="nextPrevBtns"></ng-container>
            <div class="images__image">
              <span *ngIf="image.id === (entity$ | async)?.main_image_id" data-testid="main-image-icon" class="images__main-icon">
                main image
              </span>
              <span
                class="images__details-icon-wrapper"
                (click)="openModal(image)"
                [ngClass]="developmentType | developmentTypeCSSClass"
                data-testid="item-details-button">
                <img class="images__details-icon" src="../../../../../../assets/icons/magnifying_glass_icon.svg" alt="image details icon" />
              </span>
              <app-image [base64]="image.content?.base64"></app-image>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>

    <ng-template #noImage>
      <div
        data-testid="item-add-image-field"
        class="images__wrapper images__wrapper--placeholder"
        [class.images__wrapper--editable]="editable"
        (click)="addImageBtnClick()">
        <div class="images__placeholder" (click)="onImagePlaceholderClick()">
          <i *ngIf="developmentType === DevelopmentType.researchObject" class="images__placeholder-icon images__placeholder-icon_ro"></i>
          <i *ngIf="developmentType === DevelopmentType.experiment" class="images__placeholder-icon images__placeholder-icon_exp"></i>
          <span class="images__placeholder-text">
            <span data-testid="item-add-image-placeholder" href="#" class="images__placeholder-link">Add {{ areaName() }}</span>
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</app-item-details-section>

<ng-template #nextPrevBtns>
  <ng-container *ngIf="images?.length > 1">
    <button
      data-testid="image-carousel-previous-button"
      class="images__arrow-btn images__arrow-btn--previous {{ developmentType | developmentTypeCSSClass }}"
      (click)="onCarouselPrevious()"></button>
    <button
      data-testid="image-carousel-next-button"
      class="images__arrow-btn images__arrow-btn--next {{ developmentType | developmentTypeCSSClass }}"
      (click)="onCarouselNext()"></button>
  </ng-container>
</ng-template>
<ng-template #imageDetailsModal let-modal>
  <app-item-image-details
    [developmentType]="developmentType"
    [editMode]="imageDetailsModalEditMode"
    [image]="imageDetails"
    (closeBtnClick)="onImageDetailsModalClose(modal)"
    (editModeChange)="onImageDetailsModalEditModeChange($event)"
    (descriptionChanged)="onImageDetailsModalDescriptionChanged($event)"></app-item-image-details>
</ng-template>

import { CommonModule, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Self, Optional, OnInit, signal, WritableSignal, output, input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

import { DEFAULT_SELECT_PLACEHOLDER } from '@app/domain/field/placeholders/dropdown';

@Component({
  selector: 'app-dropdown-select',
  standalone: true,
  imports: [NgSelectModule, TitleCasePipe, FormsModule, CommonModule],
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownSelectComponent<T> implements ControlValueAccessor, OnInit {
  @Input() items: T[] = [];
  @Input() colorsEnabled = false;
  @Input() clearable = false;
  @Input() bindLabel = 'name';
  @Input() bindValue = 'name';
  placeholder = input<string>(DEFAULT_SELECT_PLACEHOLDER);
  blured = output<void>();
  model: WritableSignal<T> = signal<T>(null);
  onChange = _ => {};
  onTouched = () => {};
  touched = false;

  constructor(@Self() @Optional() private ngControl?: NgControl) {
    if (ngControl) ngControl.valueAccessor = this;
  }

  ngOnInit() {
    const value = this.ngControl.value;

    if (value) {
      const initialModelValue = this.items?.find(item => item[this.bindValue] === value);
      this.model.set(initialModelValue);
    }
  }

  onSelectItem(item: T, select?: NgSelectComponent) {
    this.model.set(item);
    this.onChange(this.model()?.[this.bindValue]);
    select?.blur();
  }

  onFocusin(select: NgSelectComponent) {
    this.markAsTouched();
    select.blur();
  }

  onBlur() {
    this.blured.emit();
  }

  writeValue(value: T) {
    // added || null to resolve problem with not displaying placeholder by ng-select
    this.model.set(value || null);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onClear() {
    this.model.set(null);
    this.onChange(null);
  }
}

import { Signal, DestroyRef } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { tap, filter } from 'rxjs/operators';

import { DevelopmentItemFileDto } from '@shared/_models';
import { FileUploadStatus } from '@shared/_modules/file/models';

function wasShareAddedToDevelopmentItem(status: FileUploadStatus, files: DevelopmentItemFileDto[]): boolean {
  return files.map(file => file.name).includes(status.name);
}

function moveStatusToTheTopOfTheList(status: FileUploadStatus, statuses: FileUploadStatus[], setData: (data: FileUploadStatus[]) => void) {
  setData([status, ...statuses.filter(_status => _status !== status)]);
}

/* in UploadCardsComponent component we keep order of statuses from store,
 * only moving invalid ones to the top, but when we have for example
 * last invalid status then after fixing share name it would jump to it's
 * original position, but we want to keep it on top of the list
 * */
export function keepCreatedSharesOnTopOfTheList(
  files: Signal<DevelopmentItemFileDto[]>,
  statuses: Signal<FileUploadStatus[]>,
  setData: (data: FileUploadStatus[]) => void,
  destroyRef: DestroyRef
) {
  let status: FileUploadStatus;

  toObservable(files)
    .pipe(
      filter(() => !!status),
      takeUntilDestroyed(destroyRef),
      tap(() => {
        if (wasShareAddedToDevelopmentItem(status, files())) {
          moveStatusToTheTopOfTheList(status, statuses(), setData);
        }

        status = null;
      })
    )
    .subscribe();

  const creatingShare = (fileUploadStatus: FileUploadStatus) => (status = fileUploadStatus);

  return { creatingShare };
}

<div class="dropdown-select-container">
  <ng-select
    #select
    class="dropdown-select"
    [ngClass]="{'dropdown-select--clearable': model() && clearable}"
    [bindLabel]="bindLabel"
    popupClass="lg-dropdown-menu lg-dropdown-menu--y-scroll"
    [placeholder]="placeholder()"
    [clearable]="false"
    [editableSearchTerm]="false"
    [items]="items"
    (change)="onSelectItem($event, select)"
    (focusin)="onFocusin(select)"
    (blur)="onBlur()"
    [ngModel]="model()"
  >
    <ng-template ng-label-tmp let-item="item">
      <ng-container
        [ngTemplateOutlet]="dropdownOptionTemplate"
        [ngTemplateOutletContext]="{ template: 'selected', item: item, colorsEnabled: colorsEnabled }"
      ></ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <ng-container
        [ngTemplateOutlet]="dropdownOptionTemplate"
        [ngTemplateOutletContext]="{ template: 'option', item: item, colorsEnabled: colorsEnabled }"
      ></ng-container>
    </ng-template>
  </ng-select>
  <button class="btn btn-clear" *ngIf="model() && clearable" (click)="onClear()"></button>
</div>

<ng-template #dropdownOptionTemplate let-template="template" let-item="item" let-colorsEnabled="colorsEnabled">
  <div class="dropdown-select-value" [ngClass]="'dropdown-select-value--' + template" data-testid="select-option">
    <span *ngIf="colorsEnabled" class="status-icon" [ngClass]="'option-color-' + item.color"></span>
    <div class="dropdown-text">{{ item.name }} {{ item.description | titlecase }}</div>
  </div>
</ng-template>

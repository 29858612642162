<app-icon
  *ngIf="fieldValue()"
  class="ms-1 d-inline-flex wysiwyg-field__icon"
  [width]="iconSize()"
  [height]="iconSize()"
  fileName="info_icon.svg"
  [color]="iconColor()"
  [ngbPopover]="popover"
  triggers="mouseenter:mouseleave"
  placement="bottom-start"
></app-icon>
<span class="wysiwyg-field__header" [style]="headerCustomStyles()" [ngbTooltip]="tooltip()" [disableTooltip]="!tooltip()" [openDelay]="300" data-testid="wysiwyg-field">
  {{ fieldValue() ? 'Description' : '-' }}
</span>

<ng-template #popover>
  <div class="wysiwyg-popover" [innerHTML]="fieldValue()"></div>
</ng-template>

import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { WorkspaceDto, WorkspacePendingMemberDto } from '@shared/_models';

import * as actions from './workspaces.actions';

export const WORKSPACES_STATE_KEY = 'workspaces';
export const workspacesAdapter: EntityAdapter<WorkspaceDto> = createEntityAdapter<WorkspaceDto>();
export interface WorkspacesState extends EntityState<WorkspaceDto> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly pending: boolean;
  readonly error: HttpErrorResponse;
  readonly pendingMembers: WorkspacePendingMemberDto[];
  readonly invitedWorkspaces: WorkspaceDto[];
}

export const initialState: WorkspacesState = workspacesAdapter.getInitialState({
  loading: false,
  loaded: false,
  pending: false,
  error: null,
  pendingMembers: [],
  invitedWorkspaces: []
});

export const reducer: ActionReducer<WorkspacesState> = createReducer(
  initialState,

  on(actions.updatePending, (state, { isPending }) => ({
    ...state,
    pending: isPending
  })),
  on(actions.loadWorkspaces, state => {
    return { ...state, loading: true, loaded: false, error: null };
  }),
  on(actions.loadWorkspacesSuccess, (state, action) => {
    return workspacesAdapter.setAll(action.data, {
      ...state,
      loading: false,
      loaded: true
    });
  }),
  on(actions.loadWorkspacesFailure, (state, action) => {
    return { ...state, loading: false, loaded: false, error: action.errorResponse };
  }),
  /* we just update/add workspace loaded by route quard,
   * source of truth about selected project is in URL */
  on(actions.selectWorkspace, (state, action) => {
    return workspacesAdapter.upsertOne(action.workspace, state);
  }),
  on(actions.update, state => {
    return { ...state, error: null };
  }),
  on(actions.updateSuccess, (state, action) => {
    return workspacesAdapter.updateOne({ id: action.workspace.id, changes: action.workspace }, state);
  }),
  on(actions.updateFailure, (state, action) => {
    return { ...state, error: action.errorResponse };
  }),
  on(actions.resetError, state => {
    return { ...state, error: null };
  }),
  on(actions.inviteSuccess, (state, action) => {
    return workspacesAdapter.updateOne({ id: action.workspace.id, changes: action.workspace }, state);
  }),
  on(actions.createSuccess, (state, action) => {
    return workspacesAdapter.addOne(action.workspace, state);
  }),
  on(actions.deleteWorkspaceSuccess, (state, action) => {
    return workspacesAdapter.removeOne(action.workspaceId, state);
  }),
  on(actions.leaveSuccess, (state, action) => {
    return workspacesAdapter.removeOne(action.workspaceId, state);
  }),
  on(actions.acceptInviteSuccess, (state, action) => {
    return workspacesAdapter.upsertOne(action.workspace, state);
  }),
  on(actions.sendJoinRequestSuccess, (state, action) => {
    return workspacesAdapter.upsertOne(action.workspace, state);
  }),
  on(actions.deleteMembersSuccess, (state, action) => {
    return workspacesAdapter.updateOne({ id: action.workspace.id, changes: action.workspace }, state);
  }),
  on(actions.acceptPendingMemberSuccess, (state, action) => {
    return workspacesAdapter.updateOne({ id: action.workspace.id, changes: action.workspace }, state);
  }),
  on(actions.changeRoleSuccess, (state, action) => {
    return workspacesAdapter.updateOne({ id: action.workspace.id, changes: action.workspace }, state);
  }),
  on(actions.getPendingMembersSuccess, (state, action) => {
    return { ...state, pendingMembers: action.pendingMembers };
  }),
  on(actions.getInvitedWorkspacesSuccess, (state, action) => {
    return { ...state, invitedWorkspaces: action.invitedWorkspaces };
  })
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

//
import { SigninLoadingComponent } from '@app/_auth/signin/signin/signin-loading/signin-loading.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';

import { AuthLayoutModule } from './../auth-layout/auth-layout.module';
import { SigninAdamedComponent } from './signin/signin-adamed/signin-adamed.component';
import { SigninFormInputComponent } from './signin/signin-form/signin-form-input/signin-form-input.component';
import { SigninFormComponent } from './signin/signin-form/signin-form.component';
import { SigninComponent } from './signin/signin.component';
import { SignupTextComponent } from './signin/signup-text/signup-text.component';
import { SigninLayoutComponent } from './signin-layout/signin-layout.component';
import { SigninRoutingModule } from './signin-routing.module';

const NG_MODULES = [CommonModule, ReactiveFormsModule];

const MODULES = [SigninRoutingModule, AuthLayoutModule];

const COMPONENTS = [
  SigninComponent,
  SigninLayoutComponent,
  SigninAdamedComponent,
  SigninFormComponent,
  SignupTextComponent,
  SigninFormInputComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...MODULES, ButtonComponent, SigninLoadingComponent]
})
export class SigninModule {}

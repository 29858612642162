@if(fileDescription()){
  <button
  class="store-file-description-edit"
  (click)="editShare.emit()"
>
  <app-icon
    class="store-file-description-edit__icon"
    width="0.65rem"
    height="0.65rem"
    fileName="pen_icon.svg"
    [color]="IconColors.blue"
  ></app-icon>
  Edit
</button>
  <div class="store-file-description-label">Description</div>
  <div class="store-file-description" [innerHTML]="fileDescription()"></div>
}
<div class="file-sharing-development-items">
  @if (showDevelopmentItemSearch()) {
    <div class="input-group">
      <app-search-select
        [controlName]="FORM_KEYS.DEVELOPMENT_ITEMS_DROPDOWN_CONTROL"
        [form]="developmentItemsDropdown"
        [searchFunction]="searchDevelopmentItems"
        [resultTemplate]="developmentItemsResultTemplate"
        placeholder="To add type ID or name ..."
        (selectItem)="onLink($event)"
        [hoverColored]="true"
      ></app-search-select>
    </div>
  } @else {
    <app-button class="workspace-tertiary w-100 h-45" (click)="showDevelopmentItemSearch.set(true)">
      <span class="file-sharing-add-btn"
        >Create share</span
      >
    </app-button>
  }
  <div class="file-shares-container">
    <div class="file-shares-container__icon"></div>
    <div class="file-shares-container__label">File shares</div>
  </div>
  <div class="item-label">
    <div class="shared-label">Shares created in</div>
    <div class="shared-counters">
      <app-file-shared-counters [linkedFile]="storeFile()" [readonly]="true"></app-file-shared-counters>
    </div>
  </div>
  <div class="scrollable-container">
    <div class="container-items">
      @for (developmentItem of items(); track developmentItem.oid; let i = $index) {
        <app-grid-element-development-item
          [elementId]="fileId()"
          [elementName]="getShareName(developmentItem)"
          [developmentItemId]="developmentItem.oid"
          [developmentItemName]="developmentItem.name"
          [developmentItemCategoryName]="developmentItem.category_name"
          [developmentType]="developmentItem.development_type"
          [developmentItemsLength]="items().length"
          [developmentItemActions]="developmentItemActions"
          [developmentItemEntity]="developmentItem"
          [itemtIsFileShare]="true"
          [index]="i"
          class="scrollable-container__group__items__item"
        ></app-grid-element-development-item>
      }
    </div>
  </div>
</div>

<ng-template #developmentItemsResultTemplate let-developmentItem="result" let-searchTerm="term">
  @if (developmentItem) {
    <app-grid-element-development-item
    [developmentItemId]="developmentItem.oid"
    [developmentItemName]="developmentItem.name"
    [developmentItemCategoryName]="developmentItem.category_name"
    [developmentType]="developmentItem.development_type"
    [developmentItemsLength]="items().length"
    [itemtIsFileShare]="true"
    class="scrollable-container__group__items__item"
  ></app-grid-element-development-item>
  } @else {
    <div>
      No {{ (selectedProject$ | async)?.development_type_names.research_objects }}/{{
        (selectedProject$ | async)?.development_type_names.experiments
      }}
      found with a name containing "{{ searchTerm }}".
    </div>
  }
</ng-template>
<ng-template #unlinkDevelopmentTypeConfirmationModal let-modal>
  <app-generic-modal
    [closeButton]="{ text: 'No, I want to keep it' }"
    [acceptButton]="{ text: 'Yes, remove it' }"
    [developmentType]="selectedDevelopmentItem().development_type"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="close($event)"
  >
    <ng-template #headerTemplate>
      <app-modal-header-center
      imgSrc="toast_icon_warning.png"
      header="Are you sure you want to stop sharing file with this {{ selectedDevelopmentItem().development_type.replace('-', ' ') }}?"
    ></app-modal-header-center>
    </ng-template>
    <div class="info-modal__body">
      <span class="info-modal__body-content">
        When you
        <span class="content-highlight">remove an object from the list, it will no longer have access to the file.</span>
        Sharing with this object will be disabled and the
        <span class="content-highlight">file will not be visible in its file list.</span>
        {{ items().length === 1 ? 'This file will be deleted because it is the last place it is shared' : '' }}
      </span>
    </div>
  </app-generic-modal>
</ng-template>
<ng-template #editNameModal let-modal>
  <app-file-share-edit-name-modal
    [fileName]="getShareName(selectedDevelopmentItem())"
    [developmentType]="developmentType()"
    [developmentItemName]="selectedDevelopmentItem()?.name"
    [sourceFile]="false"
    [checkForInitialError]="checkForInitialError()"
    [shareNames]="selectedDevelopmentItemShareNames() ? selectedDevelopmentItemShareNames() : developmentTypeShareNames()"
    (closeModal)="onEditNameModalClose(modal)"
    (saveBtnClick)="onEditName($event)"
  ></app-file-share-edit-name-modal>
</ng-template>

import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';

import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { FileLinkData } from '@shared/_models';

@Component({
  selector: 'app-file-shared-counters',
  standalone: true,
  templateUrl: './file-shared-counters.component.html',
  styleUrl: './file-shared-counters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSharedCountersComponent {
  linkedFile = input.required<FileLinkData>();
  readonly = input<boolean>(false);

  sharedIsHovered = signal(false);

  readonly IconColors = IconColors;
}

import { FileUploadStatusVM } from './file-upload-status-vm';

export function orderFileUploadStatuses(a: FileUploadStatusVM, b: FileUploadStatusVM): number {
  // Prioritize items with initiallyValid: false and attached: false
  const hasPriority = (item: FileUploadStatusVM) => (!item.initiallyValid && !item.attached) || item.uploadFailed;

  if (hasPriority(a) && !hasPriority(b)) {
    return -1;
  }

  if (!hasPriority(a) && hasPriority(b)) {
    return 1;
  }

  // No specific order for other items
  return 0;
}

<div ngbDropdown class="more-actions" [placement]="placement()">
  <button
    ngbDropdownToggle
    [disabled]="disabled()"
    [ngbTooltip]="tooltip()"
    (click)="$event.stopPropagation()"
    triggers="hover"
    class="more-actions__icon more-actions__icon-more-actions"
    data-testid="more-actions-menu-button">
    @if(buttonLabel()){
      <label class="button-label">{{ buttonLabel() }}</label>
    } @else {
    <app-icon
      [width]="size()"
      [height]="size()"
      fileName="more_icon.svg"
      [color]="toggleIconColor()"
      [hoverColor]="toggleIconHoverColor()"></app-icon>
    }
  </button>
  <div ngbDropdownMenu class="dropdown__menu">
    @for (action of actions(); track action; let index = $index) {
      <a
        *ngIf="action; else separator"
        ngbDropdownItem
        class="dropdown__menu__item"
        [class.dropdown__menu__item--highlight-red]="action.highlightRed"
        [class.disabled]="action.disabled"
        [ngClass]="action.className"
        (click)="$event.stopPropagation(); onMenuItemClick(action)"
        (mouseenter)="onMenuItemHoverChange(true, index)"
        (mouseleave)="onMenuItemHoverChange(false, index)"
        data-testid="dropdown-more-item">
        <app-icon
          *ngIf="action.icon"
          [width]="action.icon.width"
          [height]="action.icon.height"
          [fileName]="action.icon.fileName"
          [color]="index === hovered() ? action.icon.hoverColor ?? action.icon.color : action.icon.color"></app-icon>
        {{ action.label }}
      </a>
      <ng-template #separator>
        <hr class="dropdown__menu__separator" />
      </ng-template>
    }
  </div>
</div>

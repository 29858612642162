import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DevelopmentItem, DevelopmentType, FileDto } from '@shared/_models';
import { ExperimentsApiService } from '@shared/_services/experiment/experiments-api.service';
import { FileApiService } from '@shared/_services/file/file-api.service';
import { ResearchObjectsApiService } from '@shared/_services/research-object/research-objects-api.service';

import { FileUploadStatus } from '../../models';

export function getRemoveShareFactory() {
  const researchObjectsApiService = inject(ResearchObjectsApiService);
  const experimentsApiService = inject(ExperimentsApiService);
  const fileApiService = inject(FileApiService);

  return (developmentItem: DevelopmentItem, status: FileUploadStatus): Observable<void> => {
    const developmentType = developmentItem.template.category.development_type;
    const file: FileDto = status.dto;
    const attached = developmentItem.files.find(share => share.file.id === file?.id);

    if (!file) return of(null);

    if (!attached) return fileApiService.delete(file.id);

    if (developmentType === DevelopmentType.researchObject) return researchObjectsApiService.removeShares(developmentItem.id, [file.id]);

    // DevelopmentType.experiment
    return experimentsApiService.removeShares(developmentItem.id, [file.id]);
  };
}

import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';

import { InputWithClearComponent } from '@app/shared/_components';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderLeftComponent } from '@app/shared/_components/modal-header-left/modal-header-left.component';
import { DevelopmentType } from '@app/shared/_models';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';

import { EditModalConfig, EditModalHeaderArgs } from './utils';

@Component({
  selector: 'app-generic-edit-modal',
  standalone: true,
  imports: [
    InputWithClearComponent,
    ReactiveFormsModule,
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    InputWithValidationComponent,
    CommonModule
  ],
  templateUrl: './generic-edit-modal.component.html',
  styleUrl: './generic-edit-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericEditModalComponent implements OnInit {
  value = input.required<string>();
  developmentType = input.required<DevelopmentType>();
  config = input.required<EditModalConfig>();
  header = input.required<EditModalHeaderArgs>();
  backendErrorMessage = input<string>();
  placeholder = input<string>();
  validators = input<ValidatorFn[]>();
  errorMessages = input<Dictionary<string>>({});
  closeModal = output<void>();
  save = output<string>();

  readonly DevelopmentType = DevelopmentType;
  control: FormControl;

  constructor() {}

  ngOnInit() {
    this.#createFormControl();
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.ACCEPT) {
      if (!this.control.valid) {
        this.control.markAllAsTouched();

        return;
      }

      this.save.emit(this.control.value);

      return;
    }

    this.closeModal.emit();
  }

  #createFormControl() {
    this.control = new FormControl(this.value(), this.validators());
  }
}

import { toDevelopmentItemUpdateFileDto } from '@app/domain/file';
import { DevelopmentItemUpdateDto, ImageDto, DevelopmentItemFile, DevelopmentItemFileDto, DevelopmentItem } from '@shared/_models';
import { FileCategory } from '@shared/_modules/file/models/file-category';

export function toFilesUpdateDto(
  uploadedFiles: DevelopmentItemFile[],
  fileCategory: FileCategory,
  developmentItem: DevelopmentItem,
  section_id?: string
): Partial<DevelopmentItemUpdateDto> {
  const initialFiles: (DevelopmentItemFileDto | ImageDto)[] =
    fileCategory === FileCategory.files ? developmentItem.files : developmentItem.images;

  return fileCategory === FileCategory.files
    ? {
        files: (initialFiles as DevelopmentItemFileDto[])
          .map(toDevelopmentItemUpdateFileDto)
          .concat(uploadedFiles.map(toDevelopmentItemUpdateFileDto))
      }
    : {
        images_ids: (initialFiles as ImageDto[])
          .map(file => ({ image_id: file.id, section_id: file.section_id }))
          .concat(uploadedFiles.map(uploadedFile => ({ image_id: uploadedFile.file.id, section_id })))
      };
}

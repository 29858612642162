import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';

import { isUnique } from '@shared/_helpers';
import { DevelopmentItemFileDto, DevelopmentType, FileDto, ImageDto } from '@shared/_models';
import { FileUploadCardComponent } from '@shared/_modules/file/file-upload-card/file-upload-card.component';
import { FileCategory } from '@shared/_modules/file/models/file-category';
import { FileUploadStatus } from '@shared/_modules/file/models/file-upload-status';

import { ShareNameData, FileUploadStatusVM, orderFileUploadStatuses } from './utils';

@Component({
  selector: 'app-upload-cards',
  standalone: true,
  imports: [FileUploadCardComponent],
  templateUrl: './upload-cards.component.html',
  styleUrl: './upload-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadCardsComponent {
  // can't be required cos of usage in toObservable(this.fileUploadStatuses);
  fileUploadStatuses = input<FileUploadStatus[]>([]);
  fileUploadStatuses$ = toObservable(this.fileUploadStatuses);
  fileCategory = input.required<FileCategory>();
  anyUploadPending = input.required<boolean>();
  developmentType = input.required<DevelopmentType>();
  files = input<DevelopmentItemFileDto[]>();
  cancel = output<string>();
  succeeded = output<{ dto: FileDto | ImageDto; guid: string; initiallyValid: boolean }>();
  inProgress = output<string>();
  failed = output<string>();
  remove = output<{ fileId: string; guid: string }>();
  shareNameChanged = output<ShareNameData>();
  create = output<string>();

  initiallyValidDictionary = signal<Record<string, boolean>>({});
  reservedNames = computed<string[]>(() => this.files()?.map(file => file.name));
  fileUploadStatusesVM = computed<FileUploadStatusVM[]>(() =>
    this.fileUploadStatuses()
      ?.map(fileUploadStatus => {
        const attached = this.files()?.some(attachedFile => attachedFile.file.id === fileUploadStatus.dto?.id);
        const initiallyValid = this.initiallyValidDictionary()[fileUploadStatus.guid];

        return {
          ...fileUploadStatus,
          attached,
          initiallyValid
        };
      })
      .sort(orderFileUploadStatuses)
  );

  constructor() {
    this.#rememberInitialValidStatuses();
  }

  #rememberInitialValidStatuses() {
    this.fileUploadStatuses$
      .pipe(
        takeUntilDestroyed(),
        tap(fileUploadStatuses => {
          if (!fileUploadStatuses?.length) return;

          const rememberedIds = Object.keys(this.initiallyValidDictionary());
          const toRemeberStatuses = fileUploadStatuses.filter(fileUploadStatus => !rememberedIds.includes(fileUploadStatus.guid));
          const toRemember = toRemeberStatuses.map(fileUploadStatus => ({
            [fileUploadStatus.guid]: isUnique(this.reservedNames(), fileUploadStatus.name)
          }));
          this.initiallyValidDictionary.update(state => ({ ...state, ...Object.assign({}, ...toRemember) }));
        })
      )
      .subscribe();
  }
}
